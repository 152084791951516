.of-hidden{
    overflow: hidden !important;
}

.verifying{
    &::before{ 
        content: 'Verifying account. Please wait...';
        position: fixed;
        z-index: 55551;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: calc(100% + 150px);
    }
    &.share{ 
        &::before{ 
            content: 'Sharing account. Please wait...';
        }
    }
}
// *{
//     transition: background-color .15s ease-in-out;
// }
.loading{
     &::before{
        content: '';
        position: fixed;
        z-index: -5;
        height: 100%;
        width: 100%;
        background: rgba(0, 1, 14, 0.95); 
        opacity: 0;
        transition: 0.4s all ease-in-out;
    }    
    &::after{
        content: '';
        position: fixed;
        z-index: -5;
        height: 100%;
        width: 100%;
        background: url(../images/corhlogo.gif);
        top: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100px; 
        opacity: 0;
        transition: 0.4s all ease-in-out;
    }
    &.on{
        transition: z-index .5s ease-out;
        &::before{
            z-index: 9999;
            transition: z-index .5s ease-out;
        }
        &::after{
            z-index: 10000;
            transition: z-index .5s ease-out;
        }

        &::before, &::after{
            opacity: 1;
            transition: 0.4s all ease-in-out;
        }
    }
}

.loader-page{ 
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: -9;
    width: 100%;
    transition: z-index .5s ease-out;
    &.on{
        z-index: 99999;
        transition: z-index .5s ease-out;
    }
    .progress{
        opacity: 0;
        transition: opacity 0.2s;
    }
    &.has-progress{
        .progress{
            opacity: 1;    
            z-index: 10050;
            width: 50%;
            height: 5px;
            margin-top: 30px;
            .progress-bard{
                background-color: #3451A9;
            }
        }
        &::after{ 
            background-position: 50% 40%;
        }
    }
}


.light-theme{ 
    @import '/themes/light/colors.scss'; 
    @import '/themes/light/variables.scss';

        
    .dropright-surgeon{
        background: none !important;
        position: absolute;
        right: 0;

        .btn{
            background: none !important;
            border: none !important;
            box-shadow: none !important;
            color: $text-color;
        }
    }
    
    &{
        background: $body-bg;
        color: $text-color;
    }
    
    .popover{
        padding: 0px;
        border: 0px;  
    }
    .popover-body{
        padding: 0px !important;
    }


    @import './_containers.scss';
    @import './_typography.scss';
    @import './_forms.scss';
    @import './_buttons.scss';
    @import './_style.scss';
    @import './_modals.scss';
    @import './_header.scss';

        
    @import './_cropper.scss'; 
    @import './_stripe.scss';
}


.dark-theme{
 
    @import '/themes/dark/colors.scss';
    @import '/themes/dark/variables.scss';
        
    .dropright-surgeon{
        background: none !important;
        position: absolute;
        right: 0;

        .btn{
            padding: 0px;
            background: none !important;
            border: none !important;
            box-shadow: none !important;
            color: $text-color;
        }
    }

    &{
        background: $body-bg;
        color: $text-color;
    }
    .popover{
        padding: 0px;
        border: 0px;  
    }
    .popover-body{
        padding: 0px !important;
    }

    @import './_containers.scss';
    @import './_typography.scss';
    @import './_forms.scss';
    @import './_buttons.scss';
    @import './_style.scss';
    @import './_modals.scss';
    @import './_header.scss';

        
    @import './_cropper.scss'; 
    @import './_stripe.scss';

}



.text-decoration-none{
    text-decoration: none !important;
}