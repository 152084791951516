/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
 .StripeElement {
    box-sizing: border-box;
    height: 34px;
    padding: 8px 0px;
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: 0 0 0 0;
    border-bottom: 2px solid #DADADA;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > div{
        height: 34px;
        width: 100%;
    }
}

.StripeElement--focus {
  box-shadow: 0 0 0;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.floaty-label{

  .brand{
    position: absolute;
    right: 0;
    width: 50px;
    font-size: 20px;
    bottom: 7px;
  }

  & > span{
    font-size: 13px;
    text-align: left;
    width: 100%;
    float: left;
  }

}

.card-details{
  border: 1px solid #DADADA;
  border-radius: 4px;
  background: $card-bg;
  img{
    height: 32px;
    margin-right: 16px;
    box-shadow: 0px 0px 1px 1px #d3d3d3;
  }
}