body{
  background: $body-bg;
  background-color: $body-bg;
}


main{
    min-height: calc(100vh - 120px);
}

&.login-page{
    min-height: calc(100vh);
}
&.register-form{ 
    min-height: calc(100vh);
    background-image: linear-gradient(135deg, rgba(65, 65, 150, 0.7) 0%, rgba(65, 65, 150, 0.7) 75%, rgba(65, 65, 150, 0.7) 100%), url('../images/nursetabbw.png');
    background-size: 45% 100%;
    background-repeat: no-repeat;
    background-position: left;  
    &::before{
        content:'';
        position: absolute;
        width: 200px;
        height: 100%;
        left: 40%;
        background: #f9f9f9;
        transform: skewX(8deg);
    }
    .logo{ 
        position: absolute;
        top: 50px;
        left: 150px;
        img{
            height: 100px;
        }
    }
    .thank-you-modal{
        
        .modal-dialog{
            background-image: linear-gradient(135deg, rgba(65, 65, 150, 0.7) 0%, rgba(65, 65, 150, 0.7) 75%, rgba(65, 65, 150, 0.7) 100%), url('../images/nursetabbw.png') !important;
        }
    }
}


.section{
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}


@media (min-width: 1400px){
    .container {
        max-width: 1280px;
    }   
}
 
main{
    padding-top: 24px !important;
    padding-bottom: 40px !important;
    transition: padding-left 0.1s;
    &.has-sidebar{
        padding-left: 65px;
        
        padding-right: 10px;
        .container {
            max-width: 1080px;
        } 
    }
    &.sidebar-open{
        padding-left: 370px;
    }
}
.footer{
    z-index: 2;
}
.middle-content{
    display: flex;
    align-items: center;
}

.sidebar-open{
    .upgrade-bar{
        width: calc(100% - 370px);
        margin-left: 370px;
    }
}

.register-form{ 
    background-image: linear-gradient(135deg, rgba(65, 65, 150, 0.7) 0%, rgba(65, 65, 150, 0.7) 75%, rgba(65, 65, 150, 0.7) 100%), url('../images/nursetabbw.png') !important;
    background-size: 45%;
    background-repeat: no-repeat;
    background-position: left;  
    &::before{
        content:'';
        position: absolute;
        width: 200px;
        height: 100%;
        left: 40%;
        background: #f9f9f9;
        transform: skewX(8deg);
    }
    .logo{ 
        position: absolute;
        top: 50px;
        left: 150px;
        img{
            height: 100px;
        }
    }
    .thank-you-modal{
        
        .modal-dialog{
            background-image: linear-gradient(135deg, rgba(65, 65, 150, 0.7) 0%, rgba(65, 65, 150, 0.7) 75%, rgba(65, 65, 150, 0.7) 100%), url('../images/nursetabbw.png') !important;
        }
    }
}

.inline-paragraph{
    .cta{
        font-weight: normal !important;
        display: unset !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}


.ar32{ 
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00010E;
    padding-top: 66.66%;
    box-sizing: content-box;
	position: relative; 
    width: 100%;
    &.loading, &.skeleton{
        background: transparent !important;
        & .react-loading-skeleton{ 
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    img {
        height: 100% !important;
        position: absolute;
        top: 0;
        width: 100% !important;
        &:hover{
            cursor: pointer;
        }
    }

    &.vimeo{
        & > div{
            height: 100% !important;
            width: 100% !important;
            position: absolute;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                cursor: pointer;
            }
        }
    }
    .bg-light{
        background: $ar-bg-light;
    }
}
.preview-media-section{
    background: $ar-bg-light;
}

.limit-reached{
    opacity: 1;
    &::before{
        content:'Upgrade to PREMIUM to view.';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: #00010E;
        color: #fff;
        opacity: 0.9;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center; 
    }
}

.in-progress{
    opacity: 1;
    &::before{
        content:'Video will be available soon.';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: #00010E;
        color: #fff;
        opacity: 0.8;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 25%;
    }
    &::after{
        content: '';
        animation: spinner-grow .75s linear infinite;
        width: 40px;
        height: 40px;
        background: white;
        position: absolute;
        top: 35%;
        opacity: 0;
        border-radius: 50%;
        z-index: 3;
    }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}

.preview-image{
    overflow: hidden !important;
    box-shadow: 0px 0px 1px 0px #ddd;
}


.inline-paragraph{
    .cta{
        font-weight: normal !important;
        display: unset !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

&.sidebar-open{
    .upgrade-bar{
        width: calc(100% - 370px);
        margin-left: 370px;
    }
}


.resize-drag {
    background-color: #29e;
    color: white;
    font-size: 14px;
    font-family: sans-serif;
    border-radius: 8px;
    padding: 8px;
    margin: 0px;
    touch-action: none;

    width: 120px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* This makes things *much* easier */
    box-sizing: border-box;
}

.resize-container {
  display: inline-block;
  width: 100%;
  height: 600px;
  background: white;
  background-size: 200px 1px;
}

.vertical-grid {
  background-size: 10px 100%;
  background-image: linear-gradient(to right,
                                    black 0%,
                                    transparent 5%,
                                    transparent 100%);
}

.horizontal-grid {
    &::before{
       position: absolute;
       top: 0;
       width: 100%;
       height: 100%;
         background-size: 100% 10px;
  background-image: linear-gradient(to bottom,
                                    black 0%,
                                    transparent 7%,
                                    transparent 100%); 
    }
}

.wmax-content{
    width: max-content;
}
.wmin-content{
    width: min-content;
}