/* COLORS 

  white
  black

  gray-light
  gray-medium
  gray-dark 

  grey-background
  body-bg

  primary-light
  primary-medium
  primary-dark
  
  secondary-light
  secondary-medium
  secondary-dark
  
  accent-light
  accent-medium
  accent-dark

  card-bg
  text-color
  shadow-color
  border-color

  primary-gradient
  secondary-gradient
  accent-gradient
  aurora-gradient

  success
  warning
  information
  error
*/
$white: #FFFFFF;
$light-bg: #FFFFFF;
$black: #000000;
$black-bg: #000000;

$gray-light:#D8D8D8;
$gray-medium:#979797;
$gray-dark:#979797;

$grey-background: #1D212E;
$body-bg: #1D212E;

$primary-light: #1E2749;
$primary-medium: #3451A9;
$primary-dark: #102040;

$secondary-light: #1D212E;
$secondary-medium: lighten(#5C5AC8, 15%);
$secondary-dark: #22216A;

$accent-light: #BBFFEA;
$accent-medium: #08CE92;
$accent-dark: #208362;

$card-bg: #1E2749;
$card-header-bg: #FFFFFF;
$text-color: rgba($white, 0.8);
$text-color-light: #000222; 
$text-color-dark: $white;

$border-color: lighten($card-bg, 25%);
$shadow-color: rgba(0,0,0,0.5);

$primary-gradient: linear-gradient(360deg, #F2F5FF 0%, #3451A9 46.29%, #102040 100%);
$secondary-gradient: linear-gradient(360deg, #F0EFFF 0%, #5C5AC8 46.29%, #22216A 100%);
$accent-gradient: linear-gradient(360deg, #BBFFEA 0%, #08CE92 46.29%, #208362 100%);
$aurora-gradient: linear-gradient(359.91deg, #08CE92 0%, #5C5AC8 35.79%, #3451A9 100%);

$success: #2ac14b;
$warning: #F5A623;
$information: #4A90E2;
$error: #f54859;