 
.navigation{
    z-index: 10;
    padding: 0px; 
    background: $nav-bg;
    box-shadow: $nav-shadow;
    position: unset !important; 
    
    @media (max-width: 769px){
        &{
            padding-right: 0px;  
            .container{
                margin-right: 0px;
                max-width: 100%;
            }
        }
    }
    .mobile-nav-container{
        @media (min-width: 769px){
            position: absolute;
        }
    }
    .mobile-nav{
        display: none !important;
        @media (max-width: 769px){
            &{
                display: block !important;
            }
        } 
        .user-item{
            .nav-link{
                display: flex;
                align-items: center; 
                .user-icon{
                    margin-right:22px;
                }
            }
        }
        .nav-item{
            color: $nav-item-color;

            *{
                color: $nav-item-color;
            }
            &.active{
                color: $nav-hover-color !important;
                .nav-link{
                    color: $nav-hover-color !important;
                }
                .nav-link b, .nav-link span{
                    text-decoration: underline !important;
                }
            }
            &.show{
                .nav-link, .nav-link b{
                    color: $nav-hover-color !important;
                }
            }
            &:first-child{
                padding-top: 20px;
            }
            &:last-child{
                padding-bottom: 20px;
            }
            
        }
        .upgrade-item{
            & .nav-link{
                justify-content: center;
                background: $upgrade-bg;
                font-weight: $bold;
                color: $upgrade-color;
            }
        }
        .nav-link{
            padding: 10px 0px;
            display: flex;
            align-items: center; 
            text-decoration: none !important;
        }
        .dropdown-toggle{ 
            &::after{
                margin-left: auto !important;
                transition: transform 0.3s;
                content: "\F143";
                transform: rotate(180deg);
                display: inline-block;
                font: normal normal normal 24px/1 "Material Design Icons" !important;
                font-size: inherit;
                text-rendering: auto;
                line-height: inherit;
                -webkit-font-smoothing: antialiased;
                border: 0px !important;
                text-decoration: none !important;
            }
        }
        
        .dropdown.show{ 
            .dropdown-toggle::after{ 
                transform: rotate(0deg);
                
            }
        }
        .dropdown-menu{
            border: 0px;
            padding: 0;
            margin: 0px;
            .dropdown-item{
                padding: 10px 30px;
            }
        }
        .search-dropdown{
            .cta{
                margin-top: 16px;
                margin-left: 0px; 
                margin-right: 0px;
                text-align: center;
                justify-content: center;
            }
        }
        .dropdown-item.active, .dropdown-item:active{ 
            color: $nav-hover-color !important;
            background: none !important;
        }
        
    }
    .navbar-collapse{
        
        @media (max-width: 769px){ 
            padding-left: 16px;
            padding-right: 16px;
        }

        &::after{
            z-index: -1;
            opacity: 0;
        } 
        &.show{ 
            border-top: 1px solid $mobile-nav-border-color;
            &::after{
                opacity: 1;
                top:unset;
                content: '';
                position: fixed;
                background: rgba(0, 1, 14, 0.85);
                height:100%;
                width: 100%; 
                z-index: 2000;
                top:unset;
                right: 0;
                left: 0; 
                opacity: 1;
                transition: .05s ease-in-out;
            }
        }
    } 
    .navbar-nav.mobile-nav{
        max-width: 425px;
        z-index: 2100;
    }
    .navbar-toggler.mobile-nav{
        background: darken($nav-toggler-bg, 5); 
        border-radius: $no-border-radius;
        height: 42px;
        font-size: $small-body-size;
        font-weight: bold;
        color: $nav-toggler-color;
        width: 76px;
    }

    .nav-logo{ 
        padding-top:0px;
        padding-bottom:0px;
        margin-right: 100px;
        padding-left: 8px;
        @media only screen and (min-width: 769px){
            &{
                padding-left: 0px;
            }
        }
        .nav-logo-img{
            height: 40px;
        }
    } 
    
    .desktop-nav{
        display: block;
        @media (max-width: 769px){
            &{display: none !important;}
        }

        
        .nav-item{ 
            color: $nav-item-color;
            font-family: $lato-font-family;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: -0.1px;
            padding: 0px; 
            line-height: 20px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:first-child{
                .nav-link{
                    padding-left: 0px;
                }
            }
            /* &:last-child:not(.user-nav){
                .nav-link{
                    padding-right: 0px;
                }
            } */

            &.active{
                color: $nav-hover-color;
                .nav-link{
                    color: $nav-hover-color; 
                }
            }
            .nav-link{
                z-index: 1;
                padding-left: 16px;
                padding-right: 16px; 
                width: 100%;
                text-align: center;
                color: $nav-item-color; 
                padding-top: 0;
                padding-bottom: 0;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.no-caret{
                    &::after {
                        display:none;
                    }
                }
                &.search-item{
                    i{
                        margin-left: 8px;
                        font-size: 20px;
                    }
                }
                &:hover{
                  text-decoration: underline !important;
                }
            }
           .dropdown-toggle::after{
                transition: transform 0.3s;
                content: "\F143";
                transform: rotate(180deg);
                display: inline-block;
                font: normal normal normal 24px/1 "Material Design Icons" !important;
                font-size: inherit;
                text-rendering: auto;
                line-height: inherit;
                -webkit-font-smoothing: antialiased;
                border: 0px !important;
                text-decoration: none !important;
            } 
            .dropdown-item.active, .dropdown-item:active{ 
                color: $nav-hover-color !important;
                background: none !important;
            }

            &:hover{
                .nav-link, 
                .nav-link *{ 
                    color: $nav-hover-color;
                }
            }
            &.dropdown{
                .search-dropdown{
                    &::after{
                        display: none;
                        z-index: -1
                    }
                }
                &.show{
                    color: $nav-dropdown-hover-color;
                    background: $nav-active-bg;
                    .nav-link, 
                    .nav-link *{ 
                        color: $nav-dropdown-hover-color;
                    }
                    
                    .search-dropdown{
                        position: fixed;
                        width: 100%;
                        top: 60px;
                        height: 80px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: $search-nav-bg;
                        z-index: 4000; 
                        .section{
                            transition: .2s ease-in-out;
                            z-index: 11; 
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background: $search-nav-bg;
                            width: 100%;
                            height: 100%;
                        }
                        &::after{
                            display: block;
                            transition: .2s ease-in-out;
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100vh;
                            background: rgba(0, 1, 14, 0.85);
                            top: 0px;
                            z-index: 9; 
                        }
                    }
                }
                .dropdown-menu{
                    margin-top: 0px;
                    padding: 0px; 
                    box-shadow: 10px 10px 10px -10px $shadow-color, -10px 10px 10px -10px $shadow-color;
                    border-radius: $no-border-radius;
                    border: 0px;
                    min-width: 100px;
                    width: 100%;
                    background: $card-bg;
                    .dropdown-item{
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        &:first-child{
                            padding-top:16px;
                        }
                        &:last-child{
                            padding-bottom: 16px;
                        }
                        color: $text-color;
                        &:hover{
                            background: darken($card-bg, 5%);
                        }
                        &.upgrade-item:hover{
                            background: darken($upgrade-bg, 5%);
                        }
                    }
                    .upgrade-item{
                        background: $upgrade-bg;
                        text-align: center;
                        color: $white;
                        font-weight: bold;
                        font-family: $lato-font-family;
                    }
                }
            }
            
            &.dropdown.show{ 
                .dropdown-toggle::after{ 
                    transform: rotate(0deg);
                }
            }
            &.notif-dropdown{
                .dropdown-menu{
                    min-width: 400px;
                    left: unset;
                    background: $card-bg;
                    .dropdown-item-text{
                        font-weight: bold;
                        font-size: $small-body-size;
                        height: 42px;
                        padding: 0 12px;
                        background: $grey-background;
                        display: flex;
                        align-items: center;
                        font-size: $body-size;
                        color: $text-color;
                    }
                    .notif-item{
                        display: flex;
                        padding: 8px 12px;
                        background: darken($card-bg, 5%);
                        transition: 0.2s ease-in-out;
                        .notif-image{
                            padding-right: 12px;
                        }
                        .notif-body{
                            white-space: pre-wrap;
                            p{
                                margin-bottom: 4px;
                                font-size: $small-body-size !important;
                            }
                            span{
                                font-weight: 100;
                                font-size: $smallest-body-size !important;
                            }
                        }
                        &:hover, & *{
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }

        .with-separator{
            .nav-item{
                .nav-link{
                    /* box-shadow: 1px 0px 0px 0px $nav-separator-color,-1px 0px 0px 0px $nav-separator-color;  */
                    border-right: 1px solid $nav-separator-color; 
                }
                &:first-child{
                    .nav-link{
                        padding-left: 16px;
                    }
                }
                &:nth-child(2){
                    .nav-link{ 
                        margin-right: -2px;
                    }
                }
                &:last-child{
                    margin-left: 1px;
                    .nav-link{
                        border-right: 0px solid $nav-separator-color; 
                        /* box-shadow: 0px 0px 0px 0px $nav-separator-color; */
                    }
                }
            }
        }
        .user-nav{
          font-size: 16px;
          padding-right: 12px;
          min-width: 190px;
          text-decoration: none !important;
          &.nav-link{
            text-decoration: none !important;
            &:hover{
                cursor: pointer;
                text-decoration: none !important;
                *{
                    border: 0 !important;
                    text-decoration: none !important;
                }
                & > span{
                    text-decoration: none !important;                
                }
            } 
          }
            &:hover{
                text-decoration: none !important;
                *{
                    border: 0 !important;
                    text-decoration: none !important;
                }
                & > span{
                    text-decoration: none !important;                    
                }
            } 
            .user-icon{
                margin-right: 16px;
            }
            span{
                max-width: 107px;
                white-space: normal;
                text-align: left;
            }
            .dropdown-menu{
                background: $card-bg;
            }
        }
        
        .search-icon{
            font-size:24px;
            margin-left: 8px;
        }
    }
}


/* FOOTER */
.footer{ 
    background: $footer-bg;
    color: $footer-color;
    *{
        font-family: $lato-font-family;
        color: $footer-color !important;
    }
    .collapse{
        display: block !important;
        .navbar-nav{
            
        }
    }
}
.corh-foot{
    background: $footer-bg;
    color: $footer-color;
    *{
        font-family: $lato-font-family;
        color: $footer-color !important;
    }
}