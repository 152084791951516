.success, .valid, .is-valid{
    color: $success;
}
.warning{
    color: $warning;
}
.information{
    color: $information;
}
.error, .invalid{
    color: $error;
}
/* IMAGE ICONS */

.cke_editable img{
	filter: invert(1);
}
.icon-sm{
    width: 28px;
    height: 28px;
}
.icon-s{
    width: 32px;
    height: 32px;
}
.icon-md{
    width: 44px;
    height: 44px;
}
.icon-lg{
    width: 52px;
    height: 52px;
}
.icon-xl{
    width: 100px;
    height: 100px;
}

.icon-circle{
    border-radius: 50%;
    &.icon-circle-sm{
        width: 50px;
        height: 50px;
    }
    &.icon-circle-lg{
        width: 100px;
        height: 100px;
    }
}

.bg-white{
    background: $white
}
.color-overite,.color-overite *{
    color: $text-color !important;
}
[class^="MsoList"] {
    text-indent: .25in !important;
    span:first-child{
        font-family: 'symbol';
    }
}

/* SURGEON CARD */
.grayed-card{ 
    filter: grayscale(1);
    position: relative;
    &::before{
        content: '';
        position: absolute;
        height: 100%;
        width: calc(100% - 40px);
        top: 0;
        z-index: 4;
        background: #00010E;
        border-radius: 4px;
        opacity: 0.6;
        filter: blur(0px);
    }
}
.loading-surgeon-card{
    &::before{
        content: 'Deleting surgeon please wait...';
        position: absolute;
        width: 100%;
        background: rgba($card-bg, 0.8);
        // background-color: $card-bg;
        left: 0;
        height: 100%;
        top: 0;
        z-index: 2;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: $error;
    }
}
.surgeon-card{
    padding: 20px;
    transition: box-shadow 0.2s;
    
    .no-underline{ 
        &:hover{
            text-decoration: none !important;
        }
    }
    &{
        border: $surgeon-card-border !important;
        border-radius: $surgeon-card-border-radius !important;
        background: $surgeon-card-bg !important;
        box-shadow: $no-shadow; 
    }
    &:hover{
        cursor: pointer;
        box-shadow: 0 2px 10px 0 $shadow-color;
    }
    .surgeon-card-body{
        display: flex;
        align-items: center;
        margin-bottom: 20px; 
        max-height: 100px;
    }
    .center-profile{
        flex-direction: column;
        max-height: unset;
        .name-holder{
            width: 100%;
            padding-top: 12px;
            .surgeon-title{
                // display: none;
            }
        }
    }
    .card-body{        
        border-radius: $surgeon-card-border-radius !important;
        background: $surgeon-card-bg !important;
        padding:0px;
    }
    .surgeon-image{
        border-radius: 50%;
        background: $gray-medium;
        margin-right: 12px; 
    }
    .surgeon-name{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 8px;
        font-family: $lato-font-family;
        word-break: break-word;
        padding-right: 8px;
    }
    .surgeon-title{
        font-weight: normal;
        font-size: 20px;
        font-family: $lato-font-family;
    }
    .surgeon-details{
        &{
            font-size: 16px; 
            margin-bottom: 12px;
        }
        &.with-btm-border{
            border-bottom: 1px solid #aaaaaa;
        }
        &:last-child{
            margin-bottom: 10px;
        }
        .item{
            &{
                margin-bottom: 8px;
            }
            &.skip{
                margin-bottom: 16px;
            }
            &:last-child{
                margin-bottom: 12px;
            }
            .item-detail{
                margin-right:40px;
            }
        }
    }
}


.add-new-secion{
    height: 44px;
    border: 2px dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
}

/* SURGEON INFO CARD */
.surgeon-info-card{

    box-shadow: $no-shadow;
    border-radius: 2px;
    border: $default-border;
    padding: 20px;

    .profile{
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        img{
            margin-bottom: 10px;
        }
        .surgeon-name{
            margin-bottom: 8px;
            word-break: break-word;
        }
        .surgeon-job{
            margin-bottom: 8px;
            word-break: break-word;
        }
        .surgeon-contact{
            margin-bottom: 10px;
            flex-wrap: wrap;
            width: 100%;
        }
    }
    .detail{
        .list{
            li{
                margin-bottom: 8px;
                &.item{
                    margin-bottom: 16px;
                }
            }
            
        }
    }
}


/* MEDIA CARD */

.media-card{
    &{
        background: $card-bg !important;
        box-shadow: $no-shadow;
        border: $default-border !important;
        padding: 0px;
    }
    .media-image-container{
        padding-top: 66.67%;
        position: relative;
        overflow: hidden;
    }
    .media-image{
        position: absolute;
        background: $gray-dark;
        width: 100%;
        top: 0;
        height: 100%;
    }
    .media-title{
        height: 52px;
        color: $text-color;
        font-size: 16px;
        font-family: Lato;
        line-height: 24px;
        font-weight: bold;
        padding: 14px 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

/* RECENT MEDIA CARD */
.recent-media-card{
    &{
        box-shadow: $no-shadow;
        border: $default-border;
        padding: 20px;
    }
    .recent-media-title{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 8px;
    }
}

/* MY RECORDS CARD */
.my-records-card{
    &{
        box-shadow: $no-shadow;
        border: $default-border;
        padding: 20px;
        margin-bottom: 24px;
    }
    .my-records-title{
        margin-bottom: 8px;
        line-height: 28px;
        height: 28px;
    }
    .my-records-tile{ 
        &{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 40px;
        }

        &:nth-last-child(-n+2){
            margin-bottom: 0px;
        }

        .my-records-value{ 
            text-align: center;
        }
        .my-records-desc{
            text-align: center;
            font-size: $h3-size;
            font-family: $lato-font-family;
        }
    }
}

/* SAVED DRAFTS CARD */
.saved-drafts-card{
    
    .saved-drafts-title{
        margin-bottom: 8px;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .saved-drafts-list{
        .item{
            &{
                padding: 12px 20px;
                border-bottom: 1px solid $border-color;
                font-size: $body-size;
                display: flex;
                flex-direction: column;
            }
            &:first-child{
                padding-top: 0px;
                padding-bottom: 12px;
            }
            &:last-child{
                padding-top: 12px;
                padding-bottom: 0px;
                border-bottom: 0px;
            }
            .saved-drafts-date{
                margin-top: 12px;
                font-size: $small-body-size;
                font-weight: $light;
            }
        }
    } 
    

}

/* ITEM PREVIEW CARD */
.item-preview-sm-card{
    
    padding: 0px;
    margin-bottom: 20px;
    /* height: calc(100% - 20px); */

    .item-img{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .item-body{
        
        padding: 20px;

        .item-name{
            margin-bottom: 16px;
        }
        .item-detail{
            
            text-align: center;

            &:not(:last-child){
                margin-bottom: 16px;
            }
            &.light{
                font-weight: $light;
            }
        }
    }

}

/* MEDICAL INVENTORY CARD */
.medical-inventory-card{
    padding: 0px;
    height: 100%;
    border-radius:4px; 
    text-decoration: none;
    color: unset !important;
    .item-img{
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }
    .item-body{
        padding: 20px;
        display: flex;
        flex-direction: column;

        .item-name{
            margin-bottom: 20px;
        }
        .item-description{
            margin-bottom: 64px;
        }
        .cta{
            align-self: end;
        }
    }
}

/* NOTIFICATION CARD */
.notification-card{

    border: $default-border;
    box-shadow: $no-shadow;
    border-radius: 0;
    padding: 20px;
    margin-bottom: 24px;
    
    .notification-body{ 
        display:flex; 

        & > img{
            margin-right:20px;
        } 
        .detail{
            display: flex;
            flex-direction: column;
            width: 90%;

            .notification-title, .notification-desc{
                margin-bottom: 8px;
                .cta{
                    display: unset;
                    margin: 0;
                } 
            }
            .notification-date{
                font-size: $small-body-size;
                font-weight: $light;
                justify-self: end;
                align-self: end;
                margin-left: auto;
            }
        }
    }
}

.jump-to-section-card{
    margin-bottom: 20px;
    .card-header{
        padding: 12px 16px !important;
        *{
            font-size: 16px;
        }
    }
    .card-body{
        padding: 12px 16px !important;
        padding-top: 0px !important; 
    }
    .list{
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        .list-item{
            list-style: none;
            margin-right: 28px;
            margin-bottom: 8px;
            *{ 
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
    &.edit{
        .list{
            flex-direction: row;
            margin-bottom: 12px;
            position: relative;
            *{
                color: $jtsc-color;
            }
            li{
                width: auto;
                background: $jtsc-bg;
                border: 1px solid;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 12px;
                padding-right: 15px; 
                padding-left: 15px; 
                padding-top: 5px; 
                padding-bottom: 5px; 
                border-radius: 5px;
            }
            
            @media (max-width: 768px){
                li{ 
                    width: 70%;
                }
            }
            @media (max-width: 468px){
                li{ 
                    width: calc(100% - 40px);
                }
            }
            .draggable-item{
                list-style: none !important;
                list-style-type: none !important;
                display: flex !important;
                border-radius: 4px !important;
                border: $jtsc-border !important;
                height: 44px !important;
                align-items: center !important;
                overflow: hidden !important;
                widows: 100% !important;
                padding-left: 0px;
                padding-right: 0px; 
                margin-right: 24px;
                .icon-holder{
                    background: $primary-medium;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 22px; 
                    .cta:hover{
                        text-decoration: none !important;
                    }
                }
                .action-holder{
                    margin-left: 20px;
                }
            }
            .add-new-secion{
                height: 44px;
                border: 2px dashed;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover{
                    cursor: pointer;
                }
            }
            .add-new-section-form{
                height: 44px; 
                display: flex; 
                width: calc(350px + 150px);   
                
                @media (max-width: 768px){
                    &{ 
                        width: calc(70% + 150px);
                    }
                }
                @media (max-width: 468px){
                    &{ 
                        width: calc(100% - 40px);
                    }
                }
                .action-holder{
                    display: flex;
                    align-items: center;
                    .cta{
                        margin: 0 !important;
                        padding: 8px 12px;
                        &:first-child{
                            margin-left: 8px !important;
                            margin-right: 8px !important;
                        }
                    }
                }
            }
        }
    }
}
.add-new-section-list{
    width: 350px;
    margin-bottom: 12px;
    color: $primary-medium;
    .add-new-secion{
        height: 44px;
        border: 2px dashed;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
            cursor: pointer;
        }
    }
}


.draggable-item{
    background: $jtsc-bg;
    list-style: none !important;
    list-style-type: none !important;
    display: flex !important;
    border-radius: 4px !important;
    border: $jtsc-border !important;
    height: 44px !important;
    align-items: center !important;
    overflow: hidden !important;
    widows: 100% !important;
    width: 350px;
    .icon-holder{
        background: $primary-medium;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px; 
    }
    &.procedure-settings{ 
        img{ 
            width: 35px;
            background: #d8d8d8;
            margin-right: 8px;
        }
    }
    .action-holder{
        margin-left: auto;
        .cta{
            *{
                color: $primary-medium;
            }
        }
        .cta:hover{
            text-decoration: none !important;
        }
        .cta:not(:first-child){
            margin-left: 4px;
        }
    }
}
/* ICON */

.icon-sm{
    width: 28px;
    height: 28px;
}
.icon-s{
    width: 32px;
    height: 32px;
}
.icon-md{
    width: 44px;
    height: 44px;
}
.icon-lg{
    width: 52px;
    height: 52px;
}
.icon-xl{
    width: 100px;
    height: 100px;
}

.icon-circle{
    border-radius: 50%;
    &.icon-circle-sm{
        width: 50px;
        height: 50px;
    }
    &.icon-circle-lg{
        min-width: 100px;
        max-width: 100px;
        height: 100px;
    }
}




/* TABS */

.custom-tabs {
    &.nav-tabs{
        border-bottom: 1px solid $gray-light;
    }
    .nav-item:not(:first-child){
        margin: 0px 12px;
    }
    .nav-item:first-child{
        margin-right: 12px;
    }
    .nav-link{
        font-size: 16px;
        background: none !important;
        border: 0px;
        border-radius: 0px;
        padding:4px 0px;
        color: $primary-medium;
        margin-bottom: -1px;
        &.active, &:hover{
            border-bottom: 0px $secondary-medium !important;
            box-shadow: 0px -3px 0px -1px $secondary-medium inset !important;
            text-decoration: none !important;
            color: $secondary-medium;
        } 
    } 
}

.zindex-1k{
    z-index: 1000;
}
.zindex-2k{
    z-index: 2000;
}
.zindex-3k{
    z-index: 3000;
}
.zindex-4k{
    z-index: 4000;
}
.zindex-5k{
    z-index: 5000;
}

/* ASSIGN ITEMS TAB */
.assign-items-tab{
    &::before{
        background: #DBDBDB;
        content: '';
        width: calc(100% - 40px);
        position: absolute;
        height: 1px;
        bottom: 0px;
        z-index: 1;
        top: 33px;
        left: 20px;
    }
}

/* SPECIALTY SPAN */

.specialty-span, 
.specialty-pill,
.tags-pill {
    font-size: 16px; 
    padding: 0px 12px;
    background: $primary-medium;
    border-radius: 17px;
    margin-right: 12px;
    color: $white !important;
    float: left;
    margin-top: 12px;
    height: 32px;
    display: flex;
    align-items: center;
}
.tags-pill{
    margin-top: 8px; 
}
td, th{
    .specialty-span{
        margin-top: 0px !important;
    }
}
.specialty-span.sm{
    height: 24px;
}


/* TABLE */
/* ON MODAL TABLE mAX HEIGHT is 350px */
.corh-table{
    overflow: auto;
    .sortable-thead:hover{
        cursor: pointer;
    }
    table{
        width: 100%;
        thead{
            background: $table-thead-bg;
            border-top: $table-thead-border;
            border-left: $table-thead-border;
            border-right: $table-thead-border;
            border-bottom: $table-thead-border;
            tr{
                td,th{
                    min-height: $table-thead-height;
                    color: $table-thead-color;
                    font-family: $table-thead-font-family; 
                    border: $no-border;
                    font-size: $table-thead-font-size;
                    padding: 10px 8px;  
                    line-height: 22px;
                    word-break: break-word;
                    &:first-child{
                        padding-left: 16px; 
                    }
                    &:last-child{
                        padding-right: 16px; 
                    }
                    i{
                        color: $table-thead-color;
                        margin-left: 8px;
                    }
                }
            }
        }
        tbody{ 
            background: $table-body-bg;
            border: $table-body-border;
            width: calc(100% + 1px);
            tr{
                border-top: $table-body-border;
                transition: .1s all;
                background: $table-body-bg;
                &:first-child{
                    border-top: $no-border;
                }
                &:hover, &.active, &.selected{
                    background: $table-body-hover-bg;
                    td:hover, td:hover *{
                        color: $table-body-hover-color;
                    }
                }
                td,th{
                    min-height: $table-body-height;
                    color: $table-body-color;
                    font-family: $table-body-font-family;
                    font-size: $table-body-font-size;
                    padding: 10px 8px;  
                    line-height: 22px;
                    word-break: break-word;
                    *{
                        font-size: $table-body-font-size !important;
                    }
                    &:first-child{
                        padding-left: 16px; 
                    }
                    &:last-child{
                        padding-right: 16px; 
                    }
                    &.has-action{
                        color: $table-body-action-color;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    &.actions{
                        font-weight: normal !important;
                        *{
                            font-weight: normal !important;
                        }
                    }
                    .custom-radio{
                        margin-right: 0px;
                        vertical-align: middle;
                        margin-left: -5px;

                        & .custom-control-label::after, 
                        & .custom-control-label::before{
                            left: -20px;
                        }
                    }
                    .custom-checkbox{
                        margin-right: 0px;
                        margin-left: -5px;
                        vertical-align: middle;

                        & .custom-control-label::after, 
                        & .custom-control-label::before{
                            left: -20px;
                        }
                        &:hover{
                            cursor: pointer;
                            & .custom-control-label:hover{
                                cursor: pointer;
                            }
                        }
                    }
                    .table-form{
                        margin: 0px;

                        .form-control{
                            border: 1px solid $table-form-border-color;
                            border-radius: 4px;
                            height: 32px;
                            line-height: 32px;
                            &.is-invalid{
                                border: 1px solid $error;
                            }
                        }
                    } 
                }
            }
        }
    }
}

$infi-bg: $body-bg;
.infi-table{
    height: 648px;
    position: relative;
    overflow-y: hidden !important;
    overflow-x: auto !important;
    // background: $infi-bg;
    // box-shadow: 0 2px 4px 0 $shadow-color;
    .infinite-scroll-component {
        padding-bottom: 32px
    }
    // &::after{
    //     content:'';
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    //     height: 100px;
    //     z-index: 3;
    //     background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #F9F9F9 100%);
    // }
    table{
        z-index: 2;
        min-height: 350px;
        max-height: 350px; 
        position: absolute;
        td,th{
            padding: 8px 12px !important;
            .specialty-span{
                margin:0px !important;
                height: 27px;
            }
            &:last-child{
                padding-right: 12px;
            }
        }
        tbody{
            max-height: 309px;
            overflow: auto; 
            display: flex;
            flex-direction: column;
            &.infinite-tbody{
                max-height: 648px;  
                overflow-y: hidden !important;
                overflow-x: auto !important;
                background: $infi-bg;
            }
            tr:nth-last-of-type(1):not(.table-notification){
                box-shadow: 0 2px 4px 0 $shadow-color; 
            }
        } 
        tr{
            display: inline-table;
            width: 100%;
        }
        thead {
            tr{
                width:  calc(100% - 10px);
            }
        }
    }
    &.with-checkbox, &.with-radio{
        td:first-child, th:first-child{
            padding-right: 0px !important;
            text-align: center !important;
        }
        td:only-child, th:only-child{
            padding-right: 12px;
        }
    }
}

/* COLLAPSIBLE DIV */
.single-collapse{

    border: 1px solid $border-color;
    border: 1px solid darken($border-color, 10%);		
    border-radius: 4px;	
    background-color: $card-bg;
    overflow: hidden;
    .header{
        display: flex;
        align-items: center;
        padding: 16px;
        background-color: $card-bg;
        color: $text-color-dark;
        &:hover{
            cursor: pointer;
        }
        .title{
            font-size: 20px;
            font-weight: bold;
            font-family: $lato-font-family;
            color: $text-color-dark;
        }
        .toggle-icon{
            transition: .3s all;
            margin-left: auto;
            margin-right: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            *{  
                color: $card-toggler-color; 
                font-size: 30px;
                line-height: 0;
            }
        }
        &.collapsed{
            .toggle-icon{
                transform: rotate(180deg);
            }
        }
    }
    
    .card-body{
        background-color: $card-bg;
        border: $no-border !important;
        border-radius: 0px;
        padding: 16px;
        &:first-child{
            padding-top: 0px;
        }
    }
}


.card:not(.card-body){
    background: $card-bg;
    border-radius: 4px;
    border: 1px solid darken($border-color, 10%);	
    // overflow: hidden;
}
.dropdown-menu{
    background: $card-bg;
    border-radius: 4px;
    *:hover{
        background: darken($card-bg, 5%);
        color: $text-color;
    }
    border: 1px solid darken($border-color, 10%);	
}
.data-card{
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid $border-color;	
    border: 1px solid darken($border-color, 10%);	
    overflow: hidden;
    background: $card-bg;
    .card-header{
        padding: 20px;
        background: $card-bg;
        border: $no-border;
    }
    .card-body{
        padding: 20px;
        &:nth-child(1), &:nth-child(2){
            padding-top: 0px;
        }
    } 
    &.item-card{
        .card-header{
            padding: 20px;
            padding-bottom: 8px;
        }
    }
}

/* TAGS CARD */
.tags-card{
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    border: 1px solid $border-color;	
    background: $card-bg;
    padding: 20px;
    .title{
        margin-bottom: 8px;
        float: left;
        width: 100%;
    }
    .tags-holder{

    }
}

/* UPGRADE BAR */
.upgrade-bar{
    display: flex;
    position: relative;
    width: 100%;
    min-height: 50px;
    background: $upgrade-bar-bg;
    justify-content: center;
    align-items: center;
    color: $upgrade-bar-color;
    padding: 10px 30px;
    & *{
        color: $upgrade-bar-color !important;
    }
    &.red{
        background: $error; 
        color: $white !important;
    }
    .cta{
        display: inline-block;
    }
    &.error{
        background: $upgrade-bar-error-bg !important;
        color: $white !important;
    }
}


/* HAS PLACEHOLDER - DIV */

.has-placeholder{
    &::after{
        content: attr(data-text);
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        border: 1px solid #DBDBDB;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 10px;
        text-align: center;
        font-size: 14px;
        z-index: 1;
    }
}

$table-notofication-family:$lato-font-family;
/* TABLE NOTIFICATION CARD */
.table-notification{
    position: relative;
    box-shadow: 10px 0px 0px 0px inset, 0 2px 4px 0 rgba(0,0,0,0.5); 
    td{
        display: table-cell;
        width: 100%; 
        .notif-container{ 
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 16px;
            .icon{
                font-size: 20px !important;
                margin-right: 8px;
            }
            .message{
                font-size: 16px !important;
                font-family: $table-notofication-family;
            }
        }
    }
    .close-action{ 
        position: absolute;
        right: 32px;
    }
}

/* TABLE ADD ROW */
.add-data-row{
    &:hover{
        cursor: pointer;
    }
    td{
        display: table-cell;
        width: 100%; 
        text-align: center;
        font-weight: bold;
    }
}

/* TABLE LOADING */
tr.table-loading{
    td, th{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    i.spinner-grow{
        margin-right: 8px;
        width: 1rem;
        height: 1rem;
    }
}
/* INFINITE TBODY */
.infinite-tbody{
    overflow: hidden;
}

/* TABLE NO DATA */
.table-nodata{

    td, th{
        display: table-cell;
    }
}
/* TIPPY */

.tippy-tooltip, .tippy-backdrop{
    background: transparent !important;
    background-color: transparent !important;
}

.tippy-tooltip{
    position: relative;
    padding: 0px;
}
.tippy-popper[x-placement^=right] .tippy-arrow{
    border-right: 20px solid $card-bg;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right-color: $card-bg !important; 
    filter: drop-shadow(-4px 0px 3px $shadow-color);
    left: -19px;
}
.tippy-popper[x-placement^=left] .tippy-arrow{
    border-left: 20px solid $card-bg;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left-color: $card-bg !important; 
    filter: drop-shadow(4px 0px 3px $shadow-color);
    right: -19px;
}

.dark-tippy{
    .tippy-tooltip{
        padding: 0px 10px;
        background: $primary-medium !important;
        color: $white;
    }
}
/* POPOVER */


/* body-image */
.centerAlign{
    display: flex;
    justify-content: center;
}
.body-image{ 
    position: relative;
    & .tooltip {
        position: absolute;  
        transform: translate3d(-50%, -50%, 0);
        border-radius: 5px;
        pointer-events: none;
        z-index: 1000;
        opacity: 1;
        color: $body-image-text-color;
    }
}
/* specialty-btn-img */
.cta{
    &:hover, &.active{
        .specialty-btn-img{
            // background: transparent;
            // filter: brightness(0) invert(1);
        }    
    }
}
.specialty-btn-img{
    // background: $gray-light;
}


/*OVERFLOWS*/
.of-hidden{
	overflow: hidden;
}
.of-auto{
	overflow: auto;
}
.ofx-auto{
	overflow-x: auto;
	overflow-y: hidden;
}
.ofy-auto{
	overflow-y: auto;
	overflow-x: hidden;
}

.c-pointer:hover,
.cursor-pointer:hover{
	cursor: pointer;
}

.c-grab:hover,
.cursor-grab:hover{
	cursor: grab;
}


/* body-image */
.centerAlign{
    display: flex;
    justify-content: center;
}
.body-image{ 
    position: relative;
    & .tooltip {
        position: absolute;  
        transform: translate3d(-50%, -50%, 0);
        border-radius: 5px;
        pointer-events: none;
        z-index: 1000;
        opacity: 1;
    }
}
/* specialty-btn-img */
.cta{
    &:hover, &.active{
        .specialty-btn-img{
            // background: transparent;
            // filter: brightness(0) invert(1);
        }    
    }
}

.specialty-btn-img{
    background: transparent;
}

/* SIDE BAR */

.sidebar{
    position: fixed;
    top: 60px;
    left: 0px; 
    height: 100%; 
    box-shadow: $siderbar-body-shadow;
    transition: width 0.2s;
    z-index: 5;
    .side-toggler{
        width: 30px;
        height: 30px;
        background: $side-toggler-bg;
        box-shadow: $side-toggler-shadow;
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: -14px;
        top: 28px;
        z-index: 5;
        transition: all 0.3s;
        &:hover{
            cursor: pointer;
        }
        *{
            color: $side-toggler-color;
            font-size: 25px;
        }
    }
    
    .collapsed{
        .surgeon-procedure-display{
            display: none !important;
        }
    }

    .side{
        width: 60px;
        height: 100%;
        background: $sidebar-bg; 
        z-index: 1;
        .side-list{
            padding: 0px;
            padding-top: 85px;
            display: flex;
            flex-direction: column;

            li{
                text-align: center; 
                transition: box-shadow 0.2s;
                &.active,&:hover{
                    box-shadow: $side-li-active-shadow;
                    background: $side-li-active-bg;
                }
                *{
                    color: $side-menu-color;
                    font-size: 40px;
                }
                .procedure_img{
                    width: 35px;
                    margin-bottom: 5px;
                }
                .surgeon_img{
                    width: 23px;
                    margin-bottom: 5px;
                }
            }
            
        }
    }

    .body{
        width: 0px; 
        .surgeon-profile-display{
            display: flex;
            flex-direction: column;
            padding-left: 28px;
            padding-right: 28px; 
            .profile-img{
                text-align: center;
                margin-top: 24px;
                margin-bottom: 24px;
                border-radius: 50%;
                width: 100px;
                height: 100px;
                align-self: center;
                overflow: hidden;
                position: relative;
                img{
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    left: 0;
                    top: 0;
                }
                a{
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 0px;
                    height: 50px;
                    background: rgba(0, 1, 14, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    font-weight: normal;
                    transition: 0.2s ease-in-out;
                }
                &:hover{
                    a{
                        opacity: 1;
                    }
                }
            }
            .name{
                text-align: center;
            }
            .name,
            .job,
            .number{
                margin-bottom: 12px;
            }
            .surgeon-details{
                .item{
                    margin-bottom: 8px;
                    &.skip{
                        margin-bottom: 16px;
                    }
                    h4{
                        margin-bottom: 4px;
                    }
                    .item-detail:first-child{
                        margin-right: 24px;
                    }
                }
            }
        }

        .surgeon-procedure-display{
            display: flex;
            flex-direction: column;
            padding-left: 28px;
            padding-right: 28px;
            padding-top: 28px;
            overflow: auto;
            max-height: calc(100vh - 105px);
            .specialty-group{
                margin-bottom: 16px;
                .title{
                    display: flex;
                    margin-bottom: 16px;
                    align-items: center;
                    .image{
                        height: 28px;
                        width: 28px;
                        margin-right: 8px;
                        background: transparent;
                    }
                }
                .procedure-title{
                    color: $secondary-medium;
                    margin-bottom: 8px;
                    &:hover{
                        cursor: pointer;
                    } 
                }
                .procedure-list{
                    padding: 0px;   
                    border: 0px;
                    border-radius: 0px;
                    background: transparent !important;
                    ul{
                        margin-bottom: 0px;
                        list-style-type: disc;
                        padding-left: 20px;
                    }
                    li{
                        margin-bottom: 8px;
                        color: $procedure-list-color;
                        *{
                            color: $procedure-list-color;
                            font-weight: normal;
                        }
                    }
                }
            }

        }
    }
    &.collapsed{ 
        .body{
            width: 0px;
        }
    }
    &:not(.collapsed){ 
        width: 370px;  
        transition: width 0.2s;
        .side-toggler{ 
            transform: rotate(180deg);
        }
        .body{
            width: calc(100% - 60px);
            background: $sidebar-body-bg;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: 2;
            margin-left: 60px;
            /* box-shadow: $siderbar-body-shadow; */
        }
    }
}


/* POPOVER TEXT */
.popover:hover{
    cursor: pointer;
}



/* POPOVER */
.icon{
    transition: all .1s;
    fill: $primary-medium;
    .stroke-here{
        stroke: $primary-medium;
        transition: all .1s;
    }
    .fill-here{
        fill: $primary-medium;
        transition: all .1s;
    }
    &:hover{ 
        cursor: pointer;
        .stroke-here{
            stroke: $secondary-medium;
            transition: all .1s;
        }
        .fill-here{
            fill: $secondary-medium;
            transition: all .1s;
        }
    }
    &.negative{
        transition: all .1s;
        fill: $white;
        .stroke-here{
            stroke: $white;
            transition: all .1s;
        }
        .fill-here{
            fill: $white;
            transition: all .1s;
        }
        .negative-stroke-here{ 
            stroke: $primary-medium;
        }
        .negative-fill-here{
            fill: $primary-medium; 
        }
        &:hover{ 
            cursor: pointer;
            .stroke-here{
                stroke: $white;
                transition: all .1s;
            }
            .fill-here{
                fill: $white;
                transition: all .1s;
            }
        }
    }
}

.fadeAway-container{
    position: relative;
    .btns{
        position: absolute;
        z-index: 2;
        &.next{
            top: -1px;
            right: -1px;
            width: 100px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 32px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 60%); 
            &:hover{
                cursor: pointer;
            }
            &.in-last{
                width: 50px;
            }
        }
        &.prev{
            top: -1px;
            left: -1px;
            width: 100px;
            height: 100%; 
            display: flex;
            height: 32px;
            justify-content: flex-start;
            align-items: center;
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 60%);
            &:hover{
                cursor: pointer;
            }
        }
        &.prev,&.next{ 
            i{
                height: 32px;
                width: 32px;
                border-radius: 50%;
                border: 1px solid $secondary-medium;
                color: $secondary-medium;
                font-size: 32px;
                padding: 0px;
                display:flex;
                justify-content: center;
                align-items:center;
                background: $white;
            }
            @media (max-width: 768px){
                &{
                    width: 35px;
                }
                i{
                    display: none;
                }
            }
        }
        @media (max-width: 768px){
            .next{
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 100%); 
            }
            .prev{
                background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #f9f9f9 100%);
            }   

        }
    }
}

.fadeAwayTabs{
    overflow-x: auto;
    overflow-y: hidden; 
    border: $no-border;
    border-radius: 0px;
    background: transparent;
    
    .nav{ 
        display: flex;
        flex-wrap: wrap;
        border: $no-border;
        .icon{ 
            width: 28px;
            height: 28px;
            margin-right: 8px;
        }
    }
}


/* TABS */

.corh-nav-tabs {
    &.nav-tabs{
        border-bottom: 1px solid $gray-light;
        height: 40px;
    }
    .nav-item:not(:first-child){
        padding: 0px 16px;
    }
    .nav-item:first-child{
        padding-right: 16px;
        &.active{
            .nav-link{ 
                border-bottom: 0px $secondary-medium !important;
                box-shadow: 0px -5px 0px -1px $secondary-medium inset !important;
                text-decoration: none !important;
                color: $secondary-medium;
            }
        }
    }
    .nav-link{
        height: 40px;
        font-size: 16px;
        background: none !important;
        border: 0px;
        border-radius: 0px;
        padding:4px 0px;
        color: $primary-medium;
        margin-bottom: -1px;
        transition: 0.2s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active, &:hover{
            border-bottom: 0px $secondary-medium !important;
            box-shadow: 0px -5px 0px -1px $secondary-medium inset !important;
            text-decoration: none !important;
            color: $secondary-medium;
        } 
    } 
}


/* TEXT EDITOR */
    /* preview */
    .text-preview{
        padding: 16px !important;
        border-radius: 4px;
        background: $text-preview-bg;
        .card-header{
            background: $text-preview-bg;
            border: 0px;
            padding: 0px 0px;
            padding-bottom: 15px;
            font-size: 20px;
            font-weight: bold;
            color: $text-preview-title-color;
        }
        .card-body{
            background: $text-preview-bg;
            padding: 0; 
            font-size: 16px;
            color: $text-preview-body-color;
            p{
                margin-top: 13px;
                margin-bottom: 13px;
                &:first-child{
                    margin-top: 0px;
                }
            }
        }
    }


/* STANDARD ELEMENT */  
/* preview */
.react-thumbnail-generator{
    height: 100% !important;
    img{
        width: 100% !important;
    }
}
.standard-preview{
    padding: 0px !important;
    border-radius: 4px;
    background: $standard-preview-bg;
    padding: 0px;
    .card-body{
        border-radius: 4px;
        padding: 0px;
        display: flex;
        overflow: hidden;
        .card-header{
            background: $standard-preview-bg;
            border: 0px;
            padding: 16px; 
            font-size: 20px;
            font-weight: bold;
            color: $standard-preview-title-color;
        }
        .card-content{ 
            background: $standard-preview-bg;
            border: 0px;
            padding: 0px 16px; 
            padding-bottom: 16px;
            font-size: 16px;
            color: $standard-preview-body-color;
            max-height: 255px;
            overflow: auto;

            p{
                margin-top: 13px;
                margin-bottom: 13px;
                &:first-child{
                    margin-top: 0px;
                }
            }
        }
    }
}


/* media album */
    /* preview */
    .media-element{
        padding:16px;
        & .card-header{
            padding: 0px;
            padding-bottom: 12px; 
            font-size: 20px;
            font-weight: bold;
            border: 0px;
            background: $card-bg;
        }
        & .card-body{
            padding: 0px;
            padding-bottom: 0px;
            background: $card-bg;
            & .preview{
                display: flex;  
                .preview-slider{
                    display: flex !important;
                }
                .slick-slider{
                    width: 100%;
                }
                .image{
                    padding-left: 0px;
                    margin-right: 8px; 
                    background: #00010E;
                    flex: 1;
                    &:hover{
                        cursor: pointer;
                    }
                }  
                .description{
                    padding-left: 8px;
                    padding-right: 0px;
                    flex: 1 10%;
                    p{
                        margin-top: 13px;
                        margin-bottom: 13px;
                        &:first-child{
                            margin-top: 0px;
                        }
                    }
                    .title{
                        margin-bottom: 8px;
                        font-weight: bold;
                    }
                    .content{
                        max-height: 240px;
                        overflow: auto;
                        word-break: break-word;
                        img{ 
                            display: inline-block !important;
                        }
                    }
                } 
            }
            & .thumbnails{
                .swiper-wrapper{
                    margin-left: calc(-40% + 32px);
                }
                .alice-carousel__stage-item{ 
                }
                .thumb-item{
                    width: calc(100%) !important; 
                    float: left;
                    padding-top: 20px;
                    padding-right: 20px;
                }
            }
        }
    }

/* ADD A NEW SECTION */

.add-section{
    padding:22px;
    border: 2px dashed $gray-dark;
    border-radius: 0px;
    border-spacing: 5px;
    &.single{
        &:hover{
            cursor: pointer;
        }
        padding: 44px;
    }
    .card-header{
        border: 0;
        background: $card-bg;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        padding:0;
    }
    .card-body{
        padding: 10% !important;
        padding-top: 28px !important;
        padding-bottom: 22px !important;
        ul{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            li{ 
                a{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    color: $secondary-medium;
                    .icon{
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}

/* CKEDITOR CUSTOM */
.cke_chrome{
    border: 0px !important;
    box-shadow: 0px 0px 0px 1px $shadow-color;
    width: 100%;
}
.cke_bottom{
    display: none;
}
.cke_top{
    background: $cke-top-bg;
    *{ 
        color:  $text-color-dark !important;
        filter: $cke-top-filter;
    }
    a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active {
        background: #f4f4f4;
    }
}
.cke_button_on {
    background: $cke_button_on-bg;
}
.cke_combo_on a.cke_combo_button, .cke_combo_off a.cke_combo_button:active{
    filter: none !important;
}
.cke_combo_text{
    filter: none !important;
}
.cke_wysiwyg_frame{ 
    filter: $cke_wysiwyg_frame-filter;
}
.cke_wysiwyg_frame, .cke_wysiwyg_div{
    background-color: $cke_wysiwyg_div-bg;
    color: $text-color !important;
    *{
        color: $text-color !important;
    }
}

/* TEXT EDITOR */
.text-editor{
    padding: 0px;
    margin-bottom: 28px;

    .card-header{
        padding: 0px 12px;
        border: 0px;
        background: darken($body-bg, 5%);
        
        // box-shadow: ;
        display: flex;
        height: 45px;
        align-items: center; 
        .move-icon{
            margin-right: auto;
            *{fill: $text-color !important;}
        }
        .copy-icon{
            margin-right: 24px;
            *{fill: $text-color !important;}
        }
        .trash-icon{
            margin-right: -3px; 
            *{fill: $error !important;}
        }
    }
    .card-body{
        padding: 12px;
        &.editor-body{
            padding: 0px !important;
        }
    }
}


/* MEDIA EDITOR */

.move-icon{ 
    *{fill: $text-color !important;}
} 
.copy-icon{ 
    *{fill: $text-color !important;}
}
.trash-icon{ 
    *{fill: $error !important;}
}
.media-editor{
    padding: 0px;
    margin-bottom: 28px;

    & > .card-header{
        padding: 0px 12px;
        border: 0px;
        background: darken($body-bg, 5%);
        display: flex;
        height: 45px;
        align-items: center; 
        .move-icon{
            margin-right: auto;
            *{fill: $text-color !important;}  
        }
        .copy-icon{
            margin-right: 24px;
            *{fill: $text-color !important;}
        }
        .trash-icon{ 
            *{fill: $error !important;}
        }
    }
    .card-body{
        padding: 12px;
        padding-top: 20px;
        &.media-body{
            background: darken($body-bg,5%);
        }
        .card.media-item{
            margin-bottom: 20px;
            .card-header{
                background: $card-bg;
                border: 0px;
                display: flex;
                .move-icon{
                    margin-right: 4px;
                    *{fill: $text-color !important;}
                } 
                .copy-icon{
                    margin-left: 24px;
                    margin-right: 24px;
                    *{fill: $text-color !important;}
                }
                .trash-icon{
                    margin-right: -3px;
                    margin-left: auto;
                    *{fill: $error !important;}
                }
            }
            & > .card-body{
                display: flex;
                position: relative;
                width: 100%;
                &.standard-body{
                    padding: 0px !important;
                    position: relative;
                    display: flex;
                    & .editor-body{
                        padding: 0px !important;
                        width: 50%;
                        display: flex;
                        .cke_chrome{
                            display: flex;
                        }
                        .cke_inner {
                            width: 100%;
                            display: flex;
                        }
                        .cke_top{
                            position: absolute;
                            box-shadow: 0px 0px 0px 1px $shadow-color;
                            border:0px;
                            width: calc(100% - 16px);
                            z-index: 1;
                            top: 0;
                            left: 0px;
                        }
                        .cke_contents{
                            margin-top: 42px;
                            width: 100%;
                            height: calc(100% - 42px) !important;
                        }
                    }
                    & .img-body{
                        padding: 0px !important;
                        width: 50%;
                        margin-top: 42px;
                    } 
                }
            }
        }
    }
}
.media-item{
            margin-bottom: 20px;
            .card-header{
                background: $card-bg;
                border: 0px;
                display: flex;
                .move-icon{
                    margin-right: 4px;
                } 
                .copy-icon{
                    margin-left: 24px;
                    margin-right: 24px;
                }
                .trash-icon{
                    margin-right: -3px;
                    margin-left: auto;
                }
            }
            & > .card-body{
                display: flex;
                position: relative;
                width: 100%;
                &.standard-body{
                    padding: 0px !important;
                    position: relative;
                    display: flex;
                    & .editor-body{
                        padding: 0px !important;
                        width: 50%;
                        display: flex;
                        .cke_chrome{
                            display: flex;
                        }
                        .cke_inner {
                            width: 100%;
                            display: flex;
                        }
                        .cke_top{
                            position: absolute;
                            box-shadow: 0px 0px 0px 1px $shadow-color;
                            border:0px;
                            width: calc(100% - 16px);
                            z-index: 1;
                            top: 0;
                            left: 0px;
                        }
                        .cke_contents{
                            margin-top: 42px;
                            width: 100%;
                            height: calc(100% - 42px) !important;
                        }
                    }
                    & .img-body{
                        padding: 0px !important;
                        width: 50%;
                        margin-top: 42px;
                    } 
                }
            }
        }

/* LABEL INFO */
.info-label{    
    border: 2px dashed $border-color;
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: darken($card-bg, 1%);
    padding-top: 66.66%;
    box-sizing: content-box;
	position: relative; 
    width: calc(100% - 3px);
    margin: 0 !important;
    &:hover{
        cursor: pointer;
    }
    &.changeable{
        &:hover{
            .change-label{
                opacity: 1;
            }    
        }
        .change-label{
            color: $white;
            opacity: 0;
            transition: opacity 0.2s;    
            z-index: 1;
            width: 100%;    
            background: rgba($color: #000000, $alpha: 0.5);
        }
    }
    & > *:not(input){
        position: absolute;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input{
        opacity: 0;
        margin-top: -30px;
    }
}

/* POPOVER ICON */
.popovericon{
    line-height: 1em;
}


/* NAVIGATOR TABS */

.navigator-tabs{ 
        &::after{ 
            background: $nav-border-bottom-color ; 
            content: '';
            width: 100%;
            position: absolute;
            height: 1px;
            bottom: 0px; 
            z-index: -1;
            top: 33px;
            left: 0px;
        }
    .fadeAwayTabs{
        
    }
    .nav-tabs{
        border-bottom: 0px solid $nav-border-bottom-color;
        position: relative;
        // &::after{
        //     content: '';
        //     width: calc(100% - 40px);
        //     position: absolute;
        //     height: 1px;
        //     bottom: 0px;
        //     background: $nav-border-bottom-color ;
        //     z-index: -1;
        // }
    }
    .nav-item{
        border: 0 !important;
        background: transparent;
        padding: 0px;
        padding-bottom: 8px;
        margin-right: 28px;
        height: 35px;
        line-height: 24px;
        font-weight: bold;
        transition: box-shadow 0.1s;
        color: $nav-color; 
        &.active, &:hover{
            color: $nav-active-color;
            text-decoration: none !important;
            box-shadow: 0px -4px 0px 0px $nav-active-color inset;            
        }
        &.disabled{
            opacity: 0.5;
            pointer-events: none !important;
            &:hover{
                cursor: disabled;
            }
        }
    }
    .tab-content{
        padding-top: 16px;
        width: 100%;
        .tab-pane{
            width: 100%;
        }
    }
}


.standard-editor{
    padding: 0px;
    margin-bottom: 28px;

    .card-header{
        padding: 0px 12px;
        border: 0px;
        background: darken($body-bg, 5%);
        display: flex;
        height: 45px;
        align-items: center; 
        .move-icon{
            margin-right: auto;
            *{fill: $text-color !important;}
        }
        .copy-icon{
            margin-right: 24px;
            *{fill: $text-color !important;}
        }
        .trash-icon{
            margin-right: -3px;
            *{fill: $error !important;}
        }
    }
    .card-body{
        padding: 12px;
        &.standard-body{
            padding: 0px !important;
            position: relative;
            display: flex;
        }
        & .editor-body{
            padding: 0px !important;
            width: 50%;
            display: flex;
            .cke_chrome{
                display: flex;
            }
            .cke_inner {
                width: 100%;
                display: flex;
            }
            .cke_top{
                position: absolute;
                box-shadow: 0px 0px 0px 1px $shadow-color;
                border:0px;
                width: calc(100% - 16px);
                z-index: 1;
                top: 0;
            }
            .cke_contents{
                margin-top: 42px;
                width: 100%;
                height: calc(100% - 42px) !important;
            }
        }
        & .img-body{
            padding: 0px !important;
            width: 50%;
            margin-top: 42px;
        }
    }
}


$end-of-loading-bg: $body-bg;
$end-of-loading-color: $text-color;
$end-of-loading-anchor-color: $secondary-medium;
/* END OF LOADING */
.end-of-loading, .loading-infi{
    background: $end-of-loading-bg;
}
.end-of-loading-title{
    font-size: 28px !important;
    color: $end-of-loading-color;
    margin-top: 32px;
}
.end-of-loading-description{
    color: $end-of-loading-color;
    font-size: 16px;
    margin-bottom: 32px;
    a{
        color: $end-of-loading-anchor-color;
        &:hover{
            color: $end-of-loading-anchor-color;
        }
    }
}

/* account-settings-tabs */
.setting-profile-card{
    position: absolute;
    width: 280px;
    right: -300px;
}
.account-settings-tabs{
    &::after{ 
        display: none;
    }
    .fadeAwayTabs{
        ::after{
            content: '';
            width: calc(100% - 40px);
            position: absolute;
            height: 1px;
            bottom: 0px;
            background: $nav-border-bottom-color ;
            z-index: -1; 
        }
    }
    .nav-item{ 
        padding-bottom: 0px;
        margin-right: 28px;
        height: 31px;
    }
}

/* trays-tabs */
.trays-tabs{
    &::after{
        width: calc(100% - 40px);
        left: 20px;
    }
}

/* edit-action-menu */
$menu-bg: $card-bg;
$line-bg: $gray-light;
.edit-action-menu{ 
    list-style: none;
    padding: 6px 8px;
    margin: 0;
    margin-bottom: 20px;
    border-radius: 4px;
    background: $menu-bg;
    box-shadow: 0px 2px 5px 0px $shadow-color;
    min-height: 44px;
    display: flex;
    border: 1px solid $border-color;
    flex-wrap: wrap;
    li{
        display: flex;
        padding-left: 12px;
        padding-right: 12px;
        position: relative;
        &.has-line{
            padding-left: 24px;
            margin-left: 13px;
            &::before{
                position: absolute;
                content: '';
                height: 32px;
                width: 1px;
                background: $line-bg;
                left: -1px
            }
        }
        .cta{
            height: 32px;
            padding-top: 0px;
            padding-bottom: 0px;
            margin: 0px !important;
        }
    }
}
/* scroll-to-top */
.scroll-to-top {
    position: fixed;
    background: $scroll-to-top-bg;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    z-index: 1040;
    left: calc(90% + 55px);
    bottom: 100px;
    i{
        color: $scroll-to-top-color;
    }
}
li{
    list-style-type: none;
}
/* SLICK SLIDER */
.thumbnails{
    background: $body-bg;   
    margin-top: 20px; 
    // padding-bottom: 20px;
    .slick-slider{
        padding-left: 56px;
        padding-right: 36px;
        padding-bottom: 20px;
    }
    .slick-track{
        min-width: 100% !important;
    }
} 
.thumb-item{
    & > .ar32{
        opacity: 0.4;
    }
    & > .ar32{
        transition: all 0.2s;
    }
    &.active-slide > .ar32{
        box-shadow: 0px -4px 0px 0px $secondary-medium;
        opacity: 1;
    }
} 
.slick-prev, .slick-next{
    background: $body-bg;
    border: 1px solid $border-color;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    &::before{
        content:'';
        color: $text-color;
    }
    i{
        color: $primary-medium;
        font-size: 40px;
        position: absolute;
        left: -1px;
        right: 0;
        top: 19px;
    }
    &.slick-disabled{
        opacity: 0.2;
        &:hover{
            cursor: disabled;
        }
    }
}
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{ 
    background: $body-bg;
}
.slick-next{ 
    right: 10px;
    top: 50%;
}
.slick-prev{
    left: 10px;
    top: 50%;
}
.slick-prev:before, .slick-next:before{  
    font-size: 40px;
}

.pricing-card{ 
    margin: 0 auto;
    border: 0;
    border-radius: 0;
    .card-header{
        border-radius: 0;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        background: $secondary-medium;
        color: #fff;
        border: 0;
        padding: 5px;
    }
    .card-body{
        .title{
            font-weight: 400;
            font-size: 24px;
            margin-top: 12px;
            margin-bottom: 24px;
        }
        ul{
            padding: 20px 10px;
            border-top: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;
            li{ 
                font-size: 14px;
                font-weight: 600;
                position: relative;
                margin-top: 12px;
                margin-bottom: 12px;
                i{
                    margin-right: 4px;
                }
                &.disabled{
                    text-decoration: line-through; 
                    color: #DBDBDB;
                    *{
                        color: #DBDBDB;
                    }
                }
                // &:first-child::after{
                //     content: '';
                //     width: 70%;
                //     height: 1px;
                //     position: absolute;
                //     background: #d1d1d1;
                //     bottom: -21px;
                //     left: 15%;
                // } 
            }
        }
    }

    &.free-plan{
        .card-header{
            background: $secondary-light;
            color: gray;
        }
        .title{

        }
    }
    &.premium{
        box-shadow: $card-shadow;
        .card-header{
            background: $secondary-medium;
            color: $primary-light;
        }
        .title{
            color: $secondary-medium;
            margin-bottom: 8px !important;
        }
        .cta-tertiary{
            font-size: 12px !important;
            color: $accent-dark !important;
            font-weight: normal;
        }
    }
}



ul.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    li{
        margin: 0px 7.5px;
        font-size: 14px;
        height: 30px;
        &.disabled{
            filter: grayscale(1);
            opacity: 0.8;
            &:hover{
                cursor: disabled;
            }
        }
        &.active{
            text-decoration: underline !important;
            a{
                font-weight: bolder;
                text-decoration: underline !important;
            }
        }
        a{
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $primary-medium;
            font-family: $lato-font-family;
            &:hover{
                text-decoration: none !important;
            }
            i{ 
                color: $primary-medium;
                font-size: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .next, .prev{
            width: 30px;
            border: 1px solid $primary-medium;
            border-radius: 50%;
        }
    }
}



/* SCROLLABLE NAVS */

$scrollable-bg-color: $body-color;
$scrollable-border-color: $border-color;
$scrollable-hover-color: $secondary-medium;
.scrollable-tab-parent{
    position: relative;
    width: 100%;
    
    .disabled{
        filter: grayscale(1);
        opacity: 0.5;
        cursor: default;
        pointer-events: unset !important;
        &:hover{
            cursor: disabled;
        }
    }
    .prev, .next{
        position: absolute;
        top: 3px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 4;
        color: $secondary-medium;
        *{
            color: $secondary-medium;
        }
        &::before{
            content: '';
            position: absolute;
            height: 26px;
            width: 26px;
            box-shadow: 0px 0px 0px 1px;
            border-radius: 50%;
        }
        &::after{
            content: '';
            position: absolute;
            height: 30px;
            width: 75px;
            z-index: -1;  
        }
        &:hover{
            cursor: pointer;
        }
    }
    .prev{
        left: 4px;
        &::after{
            left: -5px;
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%,  $body-bg 60%);
        }
        
        &::before{
            left: 0px;
        }
    }
    .next{
        right: 4px;
        &::after{
            right: -5px;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, $body-bg 60%);
        }

        &::before{
            right: 0px;
        }
    }
}
.scrollable-tabs{
    box-shadow: inset 0px -1px 0px 0px  $scrollable-border-color;
    overflow: auto;
    position: relative;
    z-index: 1;
    scrollbar-color: transparent;
    scrollbar-width: none; 
    &{
        height: 32px;
        overflow-x: hidden;
    } 
    &::-webkit-scrollbar{
        height: 0 !important;
    }
    .nav{
        width: max-content;
        width: -moz-max-content;
        width: -webkit-max-content;
        width: -o-max-content;
        width: -ms-max-content; 
        padding-right: 50px; 
    }
    .nav-link{
        height: 32px;
        padding: 0px 12px;
        // padding-bottom: 6px;
        margin: 0px 0px; 
        display: flex;
        justify-content: center; 
        align-items: center;
        font-size: 16px;
        position: relative;
        font-weight: 700;
        // box-shadow: 0 0 0 1px;
        padding-bottom: 8px;

        .icon{
            margin-right: 8px;
        }
        
        &::after{ 
            width: calc(100% - 24px);
            background-color: $scrollable-hover-color;
            bottom: 0; 
            content: '';
            position: absolute;
            height: 0;
        }

        &:first-child{
            padding-left: 0px;
            &::after{
                width: calc(100% - 12px) !important;      
                left: 0 !important;
            } 
            &:hover{
                &::after{
                    left: 0 !important;
                } 
            } 
        } 
        &.active::after{
            width: calc(100% - 24px);
            content: '';
            position: absolute;
            height: 4px;
            background-color: $scrollable-hover-color;
            bottom: 0; 
            box-shadow: inset 0px -1px 0px 0px $scrollable-border-color;
            // box-shadow: 0px -4px 0px -1px inset;
            // color: $scrollable-hover-color;
            left: 12px;
        } 
        &:hover:not(.disabled){
            color: $scrollable-hover-color;
            text-decoration: none !important;
            // box-shadow: 0px -4px 0px -1px inset;
            &::after{
                transition: height 0.2s ease-in-out;
                height: 4px;
                left: 12px;
            }
            *{
                text-decoration: none !important;
            }
        }
        &.disabled{
            cursor: default !important;
        }
    }
}


/* POPOVER */
.popover{
    padding: 0px;
    border: 0px; 
}
.popover-body{
    padding: 0px !important;
}
.popover-item-card{
    background: $popover-item-card-bg !important;
    padding: 8px;
    box-shadow: $popover-item-card-shadow !important;
    border-radius: 4px;
    text-align: left;
    .item-img{ 
        margin-bottom: 8px;
        img{
            width: 100%;
        }
    }
    .title{
        font-weight: $bold;
        margin-bottom: 8px;
        color: $popover-item-card-title-color !important;
        font-family: $popover-item-card-title-family;
        font-size: 20px !important;
        text-align: left;
    } 
    .sub-title{
        font-size: 16px !important;
        text-align: left;
    }
    .description{
        margin-bottom: 8px;
        color: $popover-item-card-description-color !important;
        font-family: $popover-item-card-description-family;
        font-size: 16px !important;
        text-align: left;
        max-height: 115px;
        overflow: hidden;    
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        p{
            margin-bottom: 12px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
        *{
            color: $popover-item-card-description-color !important;
        }
    }
    .tags{
        max-height: 125px;
        overflow: hidden;
    }
}

.cke_autocomplete_panel{
    background: darken($body-bg, 2%);
    color: $text-color;
    *{
        color: $text-color;
    }
}
.cke_autocomplete_selected{
    background: darken($body-bg, 7%);
}
 

// -- vars
$bg-color: #34495e;
$default-size: 5em;
$label-font-size: $default-size / 4;
$label-font-size-redo: $default-size * 4;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);
  
  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

// -- selectors
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  background: #ecf0f1;
  color: #444;
  font-family: 'Lato', Tahoma, Geneva, sans-serif;
  font-size: 16px;
  padding: 10px;
}

.set-size {
  font-size: 10em;
}

.charts-container {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  margin: 15px;
  position: relative;
    display: flex;
    justify-content: center;
  
  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid #3498db;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    background: $card-bg;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: $text-color;
    cursor: default;
    display: block; 
    font-size: 1.25em;
    // left: 1.4em;
    line-height: 3.7em;
    position: absolute;
    // right: 1.5em;
    text-align: center;
    top: 0px;

    .smaller {
      color: #bdc3c7;
      font-size: .45em;
      padding-bottom: 20px;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: $text-color;

      .smaller {
        color: $text-color;
      }
    }
  }
  
  @for $i from 0 through 100 { 
    &.progress-#{$i} {
        @include draw-progress($i, $primary-medium);
    }
  } 
}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;

  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }
  
  .label {
    background: transparent;
  }
  
    @for $i from 0 through 100 {
	/* PADDING */ 
  &.progress-#{$i} {
    @include draw-progress--solid($i, #3498db, $bg-color);
  }
  }
   
}


.text-underline{
    text-decoration: underline !important;
}

.left-faq, .right-faq{
    max-height: calc(100vh - 190px);
    overflow: auto;
}



.justify{
    text-align: justify;
}
.italic{
    font-style: italic;
}
.privacy{
    h1,h2,h3,h4,h5{
        font-weight: bolder;
    }
}

.terms{
    h1,h2,h3,h4{
        font-weight: bolder;
    }
    p{
        margin-bottom: 8px;
        text-align: justify;
    }

    table{
        width: 100%;
        float: left;
        margin-bottom: 16px;
        td,th{
            padding: 8px;
        } 
        th{
            width: 150px;
        }
    }
}


.cookie-modal{
    padding: 0px !important;
    height: 150px;
    top: calc(100vh - 155px);
    .close{
        display: none !important;
    }
    & .modal-dialog{
        min-height: 150px !important;
        max-height: 150px !important;
        align-items: flex-start;
        max-width: 100% !important;
    }
}


.skeletoncard{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.share-page{
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.cookie-element{
    position: fixed;
    width: 100%;
    z-index: 9000;
    bottom: 0;
    .card{
        padding: 16px;
        border-radius: 5px;
        background: $card-bg;
        box-shadow: $card-shadow;
        margin-bottom: 5px;
        h1{
            font-weight: bold;
        }
    }
}