/* FORMS

*/
/* VALIDATION */
input, select{
  color: $text-color;
}
.validation-message{
  display: flex;  
  i{
    font-size: 20px;
  }
}

input::placeholder{
  transition: 0.2s ease-in-out;
}
 
input.show-placeholder::placeholder{
  // font-weight: bold;
  color: $error;
}

.input-holder{
  margin-bottom: 40px;
  & > label{
    margin-bottom: 8px;
  }
}
.normal-input-holder{
  margin-bottom: 32px;
}

.line-form-group-v2{
  width: 100%;
  display: flex; 
  &::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: $line-form-line-color;
      bottom: 3px;
      z-index: 2;
      left: 0px;
    } 
  &{
    position: relative; 
    min-height: 34px;
  }
  &.search-bar{
    margin-top: 0px;
    width: 100%;
    padding-right: 28px;
    .input-group-prepend{ 
      position: absolute;
      height: 24px;
      width: 24px;
      border: 0px !important;
      padding:0px;
      bottom: 0px;
      right: 0px;
      .input-group-text{
        border: 0px !important;
        background: 0px;
        padding:0px;
        font-size: 20px;
      }
    }
  }
  label{ 
    z-index: 0;
    margin: 0px !important;
    position: absolute;
    bottom: 4px;
    left: 0;
    transition: all 0.24s;
    font-family: $line-form-font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    i{
      font-size: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
  input, select{
    min-height: 34px;
    padding: 4px 0px;
    background: $line-form-bg;
    border: $no-border;
    // border-bottom: $line-form-border;
    width: 100%;
    font-family: $line-form-label-font-family;
    z-index: 2;  
    position: relative;
    align-items: end;
    &:not(.show-placeholder)::placeholder{
      opacity: 0;
    }
    &:focus, 
    &:active{
      // border-bottom: $line-form-border;
      box-shadow: 0 0 0 0;
      outline: none;  
    }
  }
  select {
    margin-left: -4px;
    min-width: calc(100% + 4px);
    border-bottom: 0px !important;
    // box-shadow: $select-box-shadow;
    
  }
  input:focus + label, 
  input:active + label,
  input.has-val + label,
  input.active + label,
  select + label{
    bottom: 25px;
    font-size: $line-form-active-font-size !important; 
  }
  
  input:focus, 
  input:active,
  input.has-val,
  input.active{
    &::placeholder{
      opacity: 1;
    }
  }
  
  .validation-message{
    display: flex;
    align-items: start;
    position: absolute;
    font-family: $line-form-font-family;
    left: 0;
    line-height: 22px;
    top: 34px;
    i{
      font-size: 20px;
    }
  }

  /* VALIDATIONS */
  &.is-valid{
    margin-top: 22px !important;
    margin-bottom: 24px !important;
    // input, select{
    //   border-bottom: $line-form-border-success;
    // }
    &::after{ 
      background: $line-form-success-color;
    } 
    .validation-message{
      color: $line-form-success-color;
      i{
        
      color: $line-form-success-color;
      }
    }
  }

  &.is-warning{
    margin-top: 22px !important;
    margin-bottom: 24px !important;
    // input, select{
    //   border-bottom: $line-form-border-warning;
    // }
    
    &::after{ 
      background: $line-form-warning-color;
    } 
    .validation-message{
      color: $line-form-warning-color;
      i{
        color: $line-form-warning-color;
      }
    }
  }

  &.is-invalid{

    margin-top: 22px !important;
    margin-bottom: 24px !important;
    // input, select{
    //   border-bottom: $line-form-border-warning;
    // }
    &.mt0{
      margin-top: 0px !important;
    }
    
    &::after{ 
      background: $line-form-error-color;
    } 
    .validation-message{
      color: $line-form-error-color;
      i{
        color: $line-form-error-color;
      }
    }
    label{
        color: $line-form-error-color;
    }
  }
   
}

.form-group{
  margin-top: 20px;
  margin-bottom: 0px;
  label{
    margin-bottom: 8px;
  }
  .form-control{
    &:active,&:focus{
      outline: none !important;
      box-shadow: 0 0 0 !important;
    }
    border: $textbox-form-border;	
    border-radius: 3px;	
    background-color: $textbox-form-bg;
  }
  &.is-valid{
    .form-control{
      border: $textbox-form-border-success;	
    }
  }
  &.is-invalid{
    label{
      color: $textbox-form-error-color;
    }
    .form-control{
      border: $textbox-form-border-error;	
    }
  }
  &.is-warning{
    .form-control{
      border: $textbox-form-border-warning;	
    }
  }
}

.input-holder{
  .line-form-group-v2.is-valid, .line-form-group-v2.is-warning, .line-form-group-v2.is-invalid{
    margin-bottom: 52px !important
  }
}

$scp-bg: $white;
$scp-active-bg: $primary-medium;
$scp-color: $primary-medium;
$scp-active-color: $white;
$scp-font-family: $lato-font-family;
$scp-font-size: 16px;
$scp-border: 1px solid $primary-medium;

$scp-invalid-bg: 1px solid $white;
$scp-invalid-border: 1px solid $error;
$scp-invalid-color: $error;
.segmented-control-primary{
  width: 100%;
  margin-top: 32px;
  
  & > label{
    width: 100%;
    margin-bottom: 8px;
  }
  .btn-group{
    width: 100%;
    padding: 0px;  
    .btn{
      width: 100%;
      color: $scp-color;
      background-color: $scp-bg;
      border: $scp-border;
      &.active{
        color: $scp-active-color;
        background-color: $scp-active-bg;
      }
      &:first-child{
        border-right: 0px !important;
      }
      &:hover{
        z-index: unset;
      }
      &:not(:first-child){
        border-left: 0px !important;
      }
      &:not(:first-child)::after {
        content: '';
        position: absolute;
        width: 1px;
        height: 80%;
        background: $scp-color;
        left: 0px;
        top: 10%;
        z-index: 1;
      }
    }
  }
   
  &.is-invalid{
    label{
      color: $scp-invalid-color;
    }
    .btn{
      background-color: $scp-invalid-bg;
      border: $scp-invalid-border; 
    }
  } 
  
}

select{
  color: $text-color !important;
}
.cke_dialog_ui_input_text *,
.cke_dialog_ui_input_select *{
  color: unset !important;
}

$form-control-bg: $primary-light;

select option{
  background: $form-control-bg;
}


.contenteditable{
  background-color: $form-control-bg !important; 
  border: 1px solid $border-color;
  min-height: 112px;
  border-radius: 4px;
  padding: 5px 8px;
}

.react-tagsinput{
  background-color: $form-control-bg !important; 
  border: 1px solid $border-color;
  border-radius: 4px;
  padding: 5px 8px;
}
.react-tagsinput-tag{ 
  padding-left: 10px;
  padding-right: 10px;
  background-color: darken($color: $form-control-bg, $amount: 5%) !important;
  border: 1px solid $border-color;
  border-radius: 2px;
  color: $text-color !important;
}
.react-tagsinput-input{
  color: $text-color !important;
}
.react-tagsinput-remove{
  margin-left: 5px;
}
.form-control { 
    background-color: $form-control-bg !important;
    color: $text-color !important;
    border: 1px solid $border-color;
    // border: 1px solid lighten(#090b0f, 20%);
}