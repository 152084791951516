// Small devices (landscape phones, 576px and bellow)

@media (max-width: 576px) { 
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-xs#{$i}{padding: 1px * $i !important}
        .p-xsx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-xsy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-xst#{$i}{padding-top: 1px * $i !important;}
        .p-xsb#{$i}{padding-bottom: 1px * $i !important;}
        .p-xsr#{$i}{padding-right: 1px * $i !important;}
        .p-xsl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-xs#{$i}{margin: 1px * $i !important;}
        .m-xsx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-xsy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-xst#{$i}{margin-top: 1px * $i !important;}
        .m-xsb#{$i}{margin-bottom: 1px * $i !important;}
        .m-xsr#{$i}{margin-right: 1px * $i !important;}
        .m-xsl#{$i}{margin-left: 1px * $i !important;}
        
        .fs-xs#{$i}{font-size: 1px * $i !important;}
    } 
    .m-xsx-auto{margin-left: auto !important;margin-right: auto !important;}
    
    .container{
        padding-right: 20px;
        padding-left: 20px;
    }

   /*  .popover{
        max-width: 80% !important;
        min-width: 80% !important;
    } */

    .copy_from_menu{
        display: none !important;
    }
    .border-xs-0{
        border: none !important;
    }
    .text-xs-underline{
        text-decoration: underline !important;
    }
    .w-xs-100{
        width: 100% !important;
    }
        
    *:not(.fa):not(.mdi) {
        word-break: break-word;
    }
    .login-page{
        padding-top: 20px;
        padding-bottom: 20px;
    } 
    .standard-preview{
        & .card-body {
            flex-direction: column;
        }
    }
    .register-form{
        background-size: 100% 100% !important; 
        background-image: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 1) 100%), url('../images/nursetabbw.png') !important;
        &::before{
            opacity: 0 !important; 
        }
        .logo{  
            top: 0px;
            left: 50px;
            img{
                height: 50px;
            }
        }
    }
    
}
@media (min-width: 220px) and (max-width: 376px) { 
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-xxs#{$i}{padding: 1px * $i !important}
        .p-xxsx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-xxsy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-xxst#{$i}{padding-top: 1px * $i !important;}
        .p-xxsb#{$i}{padding-bottom: 1px * $i !important;}
        .p-xxsr#{$i}{padding-right: 1px * $i !important;}
        .p-xxsl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-xxs#{$i}{margin: 1px * $i !important;}
        .m-xxsx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-xxsy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-xxst#{$i}{margin-top: 1px * $i !important;}
        .m-xxsb#{$i}{margin-bottom: 1px * $i !important;}
        .m-xxsr#{$i}{margin-right: 1px * $i !important;}
        .m-xxsl#{$i}{margin-left: 1px * $i !important;} 

        .fs-xxs#{$i}{font-size: 1px * $i !important;}
    }  
    
    *:not(.fa):not(.mdi) {
        word-break: break-word;
    }
    .border-sm-0{
        border: none !important;
    }
    .register-form{
        background-size: 100% !important;
        background-image: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 1) 100%), url('../images/nursetabbw.png') !important;
        &::before{
            opacity: 0.2 !important;
        }
        .logo{  
            top: 0px;
            left: 50px;
            img{
                height: 50px;
            }
        }
    }
    
}


// Medium devices (tablets, 768px and up)
@media (max-width: 769px) and  (min-width: 576px) {
    
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-md#{$i}{padding: 1px * $i !important}
        .p-mdx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-mdy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-mdt#{$i}{padding-top: 1px * $i !important;}
        .p-mdb#{$i}{padding-bottom: 1px * $i !important;}
        .p-mdr#{$i}{padding-right: 1px * $i !important;}
        .p-mdl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-md#{$i}{margin: 1px * $i !important;}
        .m-mdx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-mdy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-mdt#{$i}{margin-top: 1px * $i !important;}
        .m-mdb#{$i}{margin-bottom: 1px * $i !important;}
        .m-mdr#{$i}{margin-right: 1px * $i !important;}
        .m-mdl#{$i}{margin-left: 1px * $i !important;}

        .fs-md#{$i}{font-size: 1px * $i !important;}
    } 
    .m-mdx-auto{margin-left: auto !important;margin-right: auto !important;}


    .border-md-0{
        border: none !important;
    }
    .text-md-underline{
        text-decoration: underline !important;
    }
    .w-md-100{
        width: 100%;
    }

    .register-form{
        background-size: 100% !important;
        background-image: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 75%, rgba(255, 255, 255, 1) 100%), url('../images/nursetabbw.png') !important;
        &::before{
            opacity: 0.2 !important;
        }
        .logo{  
            top: 0px;
            left: 50px;
            img{
                height: 50px;
            }
        }
    }
    
    .standard-preview{
        & .card-body {
            flex-direction: column;
        }
    }
    
}

// Large devices (desktops, 992px and up)
@media (min-width: 770px) and (max-width: 992px) {
    
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-lg#{$i}{padding: 1px * $i !important}
        .p-lgx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-lgy#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-lgt#{$i}{padding-top: 1px * $i !important;}
        .p-lgb#{$i}{padding-bottom: 1px * $i !important;}
        .p-lgr#{$i}{padding-right: 1px * $i !important;}
        .p-lgl#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-lg#{$i}{margin: 1px * $i !important;}
        .m-lgx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-lgy#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-lgt#{$i}{margin-top: 1px * $i !important;}
        .m-lgb#{$i}{margin-bottom: 1px * $i !important;}
        .m-lgr#{$i}{margin-right: 1px * $i !important;}
        .m-lgl#{$i}{margin-left: 1px * $i !important;}

        .fs-lg#{$i}{font-size: 1px * $i !important;}
    } 
    .m-lgx-auto{margin-left: auto !important;margin-right: auto !important;}
    

    .border-lg-0{
        border: none !important;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    
    @for $i from 0 through 60 {	
        /* PADDING */
        .p-xl#{$i}{padding: 1px * $i !important}
        .p-xlx#{$i}{padding-left: 1px * $i !important;padding-right: 1px * $i !important;}
        .p-xly#{$i}{padding-top: 1px * $i !important;padding-bottom: 1px * $i !important;}
        .p-xlt#{$i}{padding-top: 1px * $i !important;}
        .p-xlb#{$i}{padding-bottom: 1px * $i !important;}
        .p-xlr#{$i}{padding-right: 1px * $i !important;}
        .p-xll#{$i}{padding-left: 1px * $i !important;}


        /* MARGINS */	
        .m-xl#{$i}{margin: 1px * $i !important;}
        .m-xlx#{$i}{margin-left: 1px * $i !important;margin-right: 1px * $i !important;}
        .m-xly#{$i}{margin-top: 1px * $i;margin-bottom: 1px * $i !important;}
        .m-xlt#{$i}{margin-top: 1px * $i !important;}
        .m-xlb#{$i}{margin-bottom: 1px * $i !important;}
        .m-xlr#{$i}{margin-right: 1px * $i !important;}
        .m-xll#{$i}{margin-left: 1px * $i !important;}

        .fs-xl#{$i}{font-size: 1px * $i !important;}
    } 
    .m-xlx-auto{margin-left: auto !important;margin-right: auto !important;}
}


// RESPONSIVE MODAL

@media (min-width: 276px) and (max-width: 576px){ 
    .modal-body,
    .modal-footer,
    .modal-header{
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 30px !important;
        padding-bottom: 0px !important;
    }
    .modal-body .container,
    .modal-footer .container,
    .modal-header .container{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}
@media (min-width: 576px) and (max-width: 767.98px){ 
    .modal-body,
    .modal-footer,
    .modal-header{
        padding-left: 10px !important;
        padding-right: 10px !important;
        padding-top: 30px !important;
        padding-bottom: 0px !important;
    }
    .modal-body .container,
    .modal-footer .container,
    .modal-header .container{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

@media (max-width: 576px) and (min-width: 576px) {
    .modal-dialog {
        max-width: 90% !important;
        margin: 1.75rem auto;
    }
}
@media (min-width: 576px) {
    .modal-lg, .modal-xl {
        max-width: 90% !important;
        margin: 1.75rem auto;
    }
    
}

@media (min-width: 992px){
    .modal-lg{
        max-width: 800px;
    }
    .modal-xl {
        max-width: 1000px;
    }
}
@media (min-width: 1200px){
    .modal-xs{
        max-width: 400px !important;

    }
    .modal-md{
        max-width: 600px !important;
    }
    .modal-lg{
        max-width: 800px !important;
    }
    .modal-xl {
        max-width: 1050px !important;
    }
}


@media screen and (max-width: 1280px)  { 
    .sidebar:not(.collapsed){
        width: 270px !important;
    }
    main.sidebar-open{
        padding-left: 65px !important;
    }
    main:not(.login-page) {
        min-height: calc(100vh - 116px) !important;
    }
}
@media screen and (max-width: 1024px)  { 
    main:not(.login-page) {
        min-height: calc(100vh - 116px) !important;
    }
    .cookie-modal{ 
        padding: 0px !important;
        min-height: 200px !important;
        top: calc(100vh - 200px) !important;
        z-index: 4000 !important;
    }
    .sidebar{
        z-index: 1051 !important; 
        .surgeon-procedure-display{
            padding-left: 12px !important;
            padding-right: 12px !important;
        }
    
        &.collapsed{  
            .surgeon-procedure-display{
                display: none !important;
                width: 0px !important;
            }
        }
    }
    .modal-open{
        .sidebar{
            z-index: 1021 !important; 
        }
    }
}
@media screen and (max-width: 768px)  { 
    main:not(.login-page){
        position: relative !important;
        padding-top: 8px;
        min-height: calc(100vh - 98px) !important;
    }
    .cookie-modal{ 
        padding: 0px !important;
        min-height: 190px !important;
        top: calc(100vh - 190px) !important;
        z-index: 4000 !important;
    }
    
    

    .edit-action-menu{ 
        li{
            &.has-line{
                &::before{
                    display: none;
                }
            }
        }
    }
    .add-section {
        padding-left: 10px !important;
        padding-right: 10px !important;
        .card-body ul li{
            width: 100%;
             a{
                padding-bottom: 12px;
                padding-right: 12px;
                text-align: center !important;
                justify-content: center !important;
            }
        }
        .card-body{
        }
    }
    .standard-preview{
        .media-preview{
            padding-left: 0px !important;
        }
    }
}

@media screen and (max-width: 668px)  { 
    .cookie-modal{ 
        padding: 0px !important;
        min-height: 220px !important;
        top: calc(100vh - 220px) !important;
        z-index: 4000 !important;
    }
    .add-section {
        padding-left: 10px !important;
        padding-right: 10px !important;
        .card-body ul li{
            width: 100%;
             a{
                padding-bottom: 18px;
                padding-right: 12px;
                text-align: center !important;
                justify-content: center !important;
            }
        }
    }
    .media-element {
        padding-left: 8px !important;
        padding-right: 8px !important;
        .preview-slider{
            flex-direction: column;
            .title{
                padding-top: 8px;
            }
        }
    }
    .setting-profile-card{
        position: relative !important;
        width: 100% !important;
        left: 0px !important;
        right: 0px !important;
        margin-bottom: 20px;
    }

    main.has-sidebar{
        padding-top: 0px !important;
        padding-left: 10px !important;
    }
    .sidebar:not(.collapsed) {
        width: 100vw !important;
    }

    .sidebar .body{
        display: none !important;
    }
    .side-toggler{
        display: none !important;
    }
    

    #proc-side{
        position: relative;
        &::after{
            content: "\F140";
            position: absolute;
            top: calc(30px - 12px);
            transform: rotate(0deg);
            transition: transform .2s ease-in-out;
            color: white;
            right: 6px;
            font: normal normal normal 24px/1 "Material Design Icons";
            font-size: inherit;
            text-rendering: auto;
            line-height: inherit;
            -webkit-font-smoothing: antialiased;
        }
    }

    #user-side{
        position: relative;
        &::after{    
            content: "\F140";
            position: absolute;
            top: calc(30px - 12px);
            transform: rotate(0deg);
            transition: transform .2s ease-in-out;
            color: white;
            right: 6px;
            font: normal normal normal 24px/1 "Material Design Icons";
            font-size: inherit;
            text-rendering: auto;
            line-height: inherit;
            -webkit-font-smoothing: antialiased;
        }
    }
    .sidebar-open{
        .sidebar .body{
            display: block !important;
            box-shadow: 4px 0px 4px black;
        }
        .sidebar:not(.collapsed) {
            // width: calc(100% + 75px) !important
            
            .side-toggler{ 
                transform: rotate(270deg);
            }
        }
        .sidebar:not(.collapsed) .body{
            position: relative;
            width: 100vw !important;
            margin-left: 0 !important;
            height: unset;
            min-height: 100vh;
        }
        #proc-side.active{
            &::after{
                transform: rotate(180deg);
            }
        }
        #user-side.active{
            &::after{
                transform: rotate(180deg);
            }
        }
    }

    .side-list{
        height: 100%; 
        
    }

    .sidebar{
        position: relative !important;
        top: 0px !important;
        height: 60px !important;
        margin-bottom: 20px !important;
        margin-left: -25px;
        margin-right: -25px;
        &.collapsed{  
            .surgeon-procedure-display{
                display: none !important;
                width: 0px !important;
            }
        }
        .side-toggler{ 
            box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.5) !important;
            left: calc(50% - 17px) !important;
            top: 45px !important;
            transform: rotate(90deg);
            padding-top: 2.5px;
            padding-left: 3px;
        }
        .side{
            width: 100% !important;
            height: 100% !important;
            padding-top: 0px !important;
            .side-list{
                padding-top: 0px !important;
                flex-direction: row !important;
                margin-bottom: 0px !important;
                li{
                    width: 100%;
                     
                }
                #proc-side{
                    
                    box-shadow: -1px 0px 0px 0px #FFFFFF inset !important;
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        position: relative;
                    }
                    a::after{
                        content: 'Procedures';
                        font-size: 14px !important;
                        padding-left: 12px;
                    }
                }
                #user-side{
                    
                    box-shadow: 0px 0px 0px 0px #FFFFFF inset !important;       
                    a{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        position: relative;
                    }
                    a::after{
                        content: 'Surgeon';
                        font-size: 14px !important;
                        padding-left: 12px;
                    }
                }
            }
        }
    }

}


@media screen and (max-width: 576px)  {
    .sidebar{ 
        margin-left: -30px; 
        margin-right: -30px; 
    }
    .standard-editor {
        .standard-body{
            flex-direction: column !important;
        }
        .editor-body{
            width: 100% !important;
        }
        .cke_contents{
            margin-top: 74px !important;
            max-height: calc(100% - 74px) !important;
        }
        .img-body{
            width: 100% !important;
            margin-top: 0px !important;
        }
		}
		.media-editor{
            .standard-body{
                flex-direction: column !important;
            }
            .editor-body{
                width: 100% !important;
            }
            .cke_contents{
                margin-top: 0px !important;
                max-height: calc(100% - 0px) !important;
            }
            .img-body{
                width: 100% !important;
                margin-top: 74px !important;
            }
        }
    .login-page{
        .card-body.p30{
            padding-left: 8px !important;
            padding-right: 8px !important;
        }
    }
}



@media screen and (max-width: 398px)  {
    .standard-editor { 
        .cke_contents{
            margin-top: 108px !important;
            max-height: calc(100% - 108px) !important;
        }
        .img-body{
            width: 100% !important;
            margin-top: 0px !important;
        }
    }
    .media-editor{ 
        .img-body{
            width: 100% !important;
            margin-top: 108px !important;
        }
    }
}



@media screen and (max-width: 341px)  {
    .standard-editor { 
        .cke_contents{
            margin-top: 108px !important;
            max-height: calc(100% - 108px) !important;
        }
        .img-body{
            width: 100% !important;
            margin-top: 0px !important;
        }
    }
    .media-editor{ 
        .img-body{
            width: 100% !important;
            margin-top: 108px !important;
        }
    }
}