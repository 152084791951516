
h1,h2,h3,h4,h5,h6,b,strong, .bold, a, .link, 
.search-results, .cta, .teriary-cta{
    font-family: $lato-font-family; 
}

h1{font-size: $h1-size; line-height: 1em;}
h2{font-size: $h2-size; line-height: 1em;}
h3{font-size: $h3-size; line-height: 1em;}
h4{font-size: $h4-size; line-height: 1em;}
h5{font-size: $h5-size; line-height: 1em;}
h6{font-size: $h6-size; line-height: 1em;}
p{font-size: $body-size;}

h1,h2,h3,h4,h5,h6,p{ 
    margin-bottom: 0px;

    &.bold{
        font-weight: 700;
    }
    &.regular{
        font-weight: 400;
    }
    &.light{
        font-weight: 300;
    }
}

.bolder{
    font-family: $lato-font-family; 
    font-weight: 900;
}

.bold{
    font-family: $lato-font-family; 
    font-weight: 700;
}

.regular{
    font-weight: 400;
}

.light{
    font-weight: 300;
}

.page-title,
.page-description,
.page-detail{
    margin-bottom: 24px;
}
.page-description{
    flex-wrap: wrap;
}
.page-title{ 
    font-weight: bold !important;
    color: $text-color-dark !important;
}
.page-sub-title{ 
    font-weight: bold !important;
    color: lighten($text-color-dark, 2%) !important;
    margin-bottom: 8px;
}

.page-description{
    font-size: 20px;
    font-weight: normal;
}

.page-detail{
    font-size: 16px;
    font-weight: normal;
}

.end-of-loading-title{
    margin-bottom: 16px;
}
.end-of-loading-description{
    margin-bottom: 16px;
}

.title-row{
    margin-bottom: 16px;
}
.title{
    color: $text-color-dark;
}
.page-toolbar{ 

    .sub-div{
        margin-bottom: 28px;
    }
    .cta{ 
        margin-bottom: 28px !important;
    }
    @media screen and (max-width: 768px){
        .has-expand{
            flex-direction: column !important
        }
        .cta{
            margin: 0px !important;  
            margin-bottom: 28px !important;
        }
    }
}

/* NOTIFIER */
 
.notifier-div{
    box-shadow: $notifier-shadow, $indicator-shadow;
    background: $notifier-bg; 
    min-height: 42px;
    
    &.success,.valid{
        * {
            color: $success !important;
        }
    }
    &.error,.invalid{
        * {
        color: $error !important;
        }
    }
    &.information{
        * {
        color: $information !important;
        }
    }
    &.warning{
        * {
        color: $warning !important;
        }
    }

    .body{ 
        display: flex;
        justify-content: center;
        align-items: center; 
        color: unset;
        .mdi{
            margin-left: 20px;
        }
    }
    .close-notification{
        top: calc(50% - 12px);
        margin-right: 12px;
        position: absolute;
        right: 0px;
    }
    .fit-this{ 
        padding: 8px;
        padding-right: 50px;
    }
}


.night-icons{
    position: absolute;
    top: 4px;
    &.mdi-weather-night{
        left: 8px;
    }
    &.mdi-weather-sunny{
        right: 8px;
    }
}

a, .link{
    color: $secondary-medium;
    &:hover{
        cursor: pointer;
    }
}