/* VARIABLES */

/* misc */
$no-border-radius: 0px;
$no-border: 0px;
$card-shadow: 0 2px 10px 0 $shadow-color;
$no-shadow: 0 0 0 0;
$default-border: 1px solid $border-color;

/* TYPOGRAPHY */
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700|Oxygen:300,400,700&display=swap');

*:not(.fa):not(.mdi){
    font-family: 'Oxygen', sans-serif; 
}

$oxygen-font-family: 'Oxygen', sans-serif;
$lato-font-family: 'Lato', sans-serif !important;

$primary-font-family: $lato-font-family;
$secondary-font-family: $oxygen-font-family;

$h1-size: 32px;
$h2-size: 24px;
$h3-size: 20px;
$h4-size: 16px;
$h5-size: 16px;
$h6-size: 16px;

$body-size: 16px;
$small-body-size: 14px;
$smallest-body-size: 14px;

/* @media (max-width: 769px){
    $h1-size: 26px;
    $h2-size: 22px;
    $h3-size: 18px;
    $h4-size: 16px;
    $h5-size: 16px;
    $h6-size: 14px;

    $body-size: 16px;
    $small-body-size: 14px;
    $smallest-body-size: 14px;
} */

$bold: 700;
$regular: 400; 
$light: 300; 


/* Navigation Variables*/

$nav-bg: $white;
$nav-active-bg: $secondary-medium;
$nav-hover-bg: $secondary-medium;
$nav-hover-color: $secondary-medium;
$nav-dropdown-hover-bg: $secondary-medium;
$nav-dropdown-hover-color: $primary-light;
$nav-shadow: 0 2px 10px 0 $shadow-color;
$nav-item-color: $text-color;
$nav-separator-color: $text-color;
$search-nav-bg: $primary-light;
$nav-toggler-bg: $secondary-medium;
$nav-toggler-color: $white;
$mobile-nav-border-color: $white;
$upgrade-bg: $accent-dark;
$upgrade-color: $primary-light;

/* FOOTER */
$footer-bg: #b7b7b7;
$footer-color: $text-color;

/* BUTTONS */


$primary-bg: $accent-dark;
$primary-hover-bg: $accent-dark;
$primary-img-bg: $white;
$primary-text-color: $white;
$primary-outline-text-color: $accent-dark;
$primary-border-color: $accent-dark;
$primary-outline-border-color: $accent-dark;
$primary-outline-hover-text-color: $white;

$secondary-bg: $primary-medium;
$secondary-hover-bg: $primary-medium;
$secondary-img-bg: $white;
$secondary-text-color: $white;
$secondary-outline-text-color: $primary-medium;
$secondary-border-color: $primary-medium;
$secondary-outline-border-color: $primary-medium;
$secondary-outline-hover-text-color: $white;

$teriary-text-color: $secondary-medium;


/* SURGEON CARD VARIABLES */

$surgeon-card-bg: $white;
$surgeon-card-border: 1px solid $gray-light;
$surgeon-card-border-radius: 4px; 


/* LINE INPUT VARIABLE */

$line-form-border:  2px solid $border-color;
$select-box-shadow: 2px -4px 0px -2px $border-color inset;
$line-form-line-color: $border-color;
$line-form-bg: transparent;
$line-form-font-family: $primary-font-family;
$line-form-label-font-family: $secondary-font-family;
$line-form-font-size: 16px;
$line-form-active-font-size: 13px;

/* VALIDATIONs */
$line-form-success-color: $success;
$line-form-warning-color: $warning;
$line-form-error-color: $error;
$line-form-border-success:  2px solid $line-form-success-color;
$line-form-border-warning:  2px solid $line-form-warning-color;
$line-form-border-error:  2px solid $line-form-error-color;

/* TEXT BOXES VARIABLE */

$textbox-form-border:  2px solid $border-color;
$textbox-form-bg: $white;
$textbox-form-font-family: $primary-font-family;
$textbox-form-label-font-family: $secondary-font-family;
$textbox-form-font-size: 16px; 

/* VALIDATIONs */
$textbox-form-success-color: $success;
$textbox-form-warning-color: $warning;
$textbox-form-error-color: $error;
$textbox-form-border-success:  2px solid $textbox-form-success-color;
$textbox-form-border-warning:  2px solid $textbox-form-warning-color;
$textbox-form-border-error:  2px solid $textbox-form-error-color;


/* TABLE */

$table-thead-bg: $primary-medium;
$table-thead-color: $white;
$table-thead-font-size: 16px;
$table-thead-height: 42px;
$table-thead-font-family: $lato-font-family;
$table-thead-border: 1px solid $primary-medium;

$table-body-bg: $white;
$table-body-border: 1px solid $gray-light;
$table-body-color: $text-color;
$table-body-action-color: $primary-medium;
$table-body-hover-color: $secondary-medium;
$table-body-font-size: 14px;
$table-body-height: 42px; 
$table-body-font-family: $oxygen-font-family;
$table-body-hover-bg: $primary-light;
$table-form-border-color: #9f9f9f;

/* UPGRADE BAR */
$upgrade-bar-bg: $accent-dark;
$upgrade-bar-color: $white;
$upgrade-bar-error-bg: $error;

$card-toggler-color: $primary-medium;
/* STANDARD PREVIEW */
$standard-preview-bg: $white;
$standard-preview-title-color: $text-color-dark;
$standard-preview-body-color: $text-color;

/* TEXT PREVIEW */

$text-preview-bg: $white;
$text-preview-title-color: $text-color-dark;
$text-preview-body-color: $text-color;

/* NAVIGATOR TABS */
$nav-color: $primary-medium;
$nav-active-color: $secondary-medium;
$nav-border-bottom-color: $border-color;

 
/* MODALS */
 
$modal-bg: $body-bg; 

/* POPOVER VARIABLEs */

$popover-item-card-bg: $white;
$popover-item-card-shadow: 0 2px 10px 0 $shadow-color;
$popover-item-card-title-color: $text-color-dark;
$popover-item-card-title-family: $lato-font-family;
$popover-item-card-description-color: $text-color;
$popover-item-card-description-family: $oxygen-font-family;
 
/* SIDE BAR */
$sidebar-bg: #303D72;
$sidebar-body-bg: $body-bg;
$siderbar-body-shadow: 0 2px 10px 0 $shadow-color;
$side-toggler-bg: $secondary-medium;
$side-toggler-color: $white;
$side-toggler-shadow: 0 0 3px 0 $shadow-color;
$side-li-active-shadow: -4px 0px 0px 0px $white inset; 
$side-li-active-bg: $secondary-medium;
$side-menu-color: $primary-light;


/* PROCEDURE LIST */
$procedure-list-color: darken($secondary-medium, 5%);


$jtsc-border: 1px solid $border-color;
$jtsc-bg: $card-bg;
$jtsc-color: $primary-medium;

$cke-top-bg: $body-bg;
$cke-top-filter: none;
$cke_button_on-bg: #fff;
$cke_combo_text-color: #000;
$frame_filter: none;
$cke_wysiwyg_frame-filter: none;
$cke_wysiwyg_div-bg: unset;


/* NOTIFIER */

$notifier-bg: $white;
$notifier-shadow: 0 2px 4px 0 $shadow-color;
$indicator-shadow: 10px 0px 0px 0px inset;

$ar-bg-light: unset;
$body-image-text-color: $primary-dark;

$scroll-to-top-bg: $secondary-medium;
$scroll-to-top-color: $white;
